import { SELECTED_COMPANY_LOCALSTORAGE_KEY } from '@erbfactors/feature-company/shared';
import { USER_STORAGE_KEY, logout, EMPTY, refresh, after_login } from '@erbfactors/util-auth';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { SnackBarService, CloseLoadingScreen } from '@erbfactors/shared-ui';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class GlobalErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private accessToken = '';

  constructor(
    private snackBar: SnackBarService,
    private router: Router,
    private store: Store
  ) {}
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((res: HttpErrorResponse) => {
        // Unauthorized AND PATH !=
        ///supplierinvitation/pending?Guid=ac826db4-d7da-49d6-98d5-110ca749e047&Vrn=033721888'

        if (res.status === 401 && !this.router.url.includes('supplierinvitation/pending') && !this.router.url.includes('supplierinvitation/modify')) {
          // if RefreshToken returns 401 then logout & navigate to login
          if (req.url.includes('Login/RefreshToken')) {
            setTimeout(() => {
              this.snackBar.openSnackbar({
                message:
                  'Η σύνδεση σας έληξε. Ανακατεύθυνση στην σελίδα εισαγωγής.',
                cssClass: ['snackbar-error'],
              });
              localStorage.removeItem(USER_STORAGE_KEY);
              localStorage.removeItem(SELECTED_COMPANY_LOCALSTORAGE_KEY);
              // this.store.dispatch(logout());
              setTimeout(() => {
                this.router.navigate(['/login']);
                this.store.dispatch(CloseLoadingScreen());
              }, 2000);
            }, 2000);

          } else {
            return this.handle401Error(req, next);
          }
        }

        // USER LOCKED
        if (res.status === 423) {
          setTimeout(() => {
            this.snackBar.openSnackbar({
              message:
                'Η είσοδός σας στην εφαρμογή έχει ανασταλεί λόγω πολλαπλών αποτυχημένων προσπαθειών. Θα ανακατευθυνθείτε αυτόματα στην σελίδα ενεργοποίησης χρήστη για να ορίσετε νέο κωδικό πρόσβασης.',
              cssClass: ['snackbar-error'],
            });
            localStorage.removeItem(USER_STORAGE_KEY);
            localStorage.removeItem(SELECTED_COMPANY_LOCALSTORAGE_KEY);
            if (!req.url.includes('login/login')) {
              this.store.dispatch(logout());
            }
            setTimeout(() => {
              this.router.navigate(['/user-activation']);
              this.store.dispatch(CloseLoadingScreen());
            }, 5000);
          }, 2000);
        }


         //INCORRECT USER CREDENTIALS 403 Forbidden
        if (res.status === 403) {
          setTimeout(() => {
            this.snackBar.openSnackbar({
              message:
                "Τα στοιχεία σύνδεσης δεν είναι σωστά",
              cssClass: ['snackbar-error'],
            });
            localStorage.removeItem(USER_STORAGE_KEY);
            localStorage.removeItem(SELECTED_COMPANY_LOCALSTORAGE_KEY);
            if (!req.url.includes('login/login')) {
              this.store.dispatch(logout());
              setTimeout(() => {
                this.router.navigate(['/login']);
                this.store.dispatch(CloseLoadingScreen());
              }, 2000);
            }
          }, 2000);
        }

         //HANDLE  ERRORS 400
          if (res.status === 400) {
            setTimeout(() => {
              this.snackBar.openSnackbar({
                message:
                  res.error != null ? res.error.Messages[0] : "SOMETHING WENT WRONG",
                cssClass: ['snackbar-error'],
              });
            }, 2000);
          }
        //return  EMPTY;
        return throwError(res);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      this.store.dispatch(refresh());

      this.store.select(after_login)
      .subscribe(
        ((data: any) => {
          if (this.accessToken == '' || this.accessToken != data?.auth?.token) {
            this.accessToken = data?.auth?.token;
            this.isRefreshing = false;
            this.refreshTokenSubject.next(this.accessToken);
            return next.handle(request);
          }
        })
      );
    }

    return this.refreshTokenSubject
      .pipe(
        filter(token => token !== null),
        take(1),
        switchMap((token) => next.handle(request))
      );
  }

  navigateToForgotPassword(){

  }
}
