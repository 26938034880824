import { AbstractControl, ValidatorFn } from '@angular/forms';

export function UserPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value) return null;
    return userPhoneValidator(control.value);
  };
}

export function userPhoneValidator(phone: string) {
  const regex = new RegExp(/^(69)\d{8}$/);
  const res = regex.test(phone);
  const error = { custom: true };
  return res ? null : error;
}
