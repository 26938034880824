import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SnackBarService } from '@erbfactors/shared-ui';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { is_factor } from './../store/auth.selectors';

@Injectable()
export class FactorGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    private snackbar: SnackBarService
  ) {}
  canActivate() {
    return this.store.select(is_factor).pipe(
      tap((bool) => {
        if (!bool) {
          this.snackbar.openSnackbar({
            message: 'Δεν έχετε πρόσβαση στην σελίδα που επιλέξατε.',
            cssClass: ['snackbar-error'],
          });
          this.router.navigate(['/dashboard']);
        }
      })
    );
  }
}
