export enum FormStatusEnum {
  Created,
  Pending = 10,
  Draft = 20,
  PendingForModification = 22,
  Expired = 23,
  PendingApprovalByERB = 30,
  RejectByFactoring = 35,
  ApprovedByFactoring = 40,
}
export enum TranslatedStatusEnum {
  'Απεστάλη',
  'Σε εκρεμμότητα' = 10,
  'Σε επεξεργασία' = 20,
  'Απαιτέίται τροποποίηση' = 22,
  'Ληγμένη' = 23,
  'Αναμονή Έγκρισης' = 30,
  'Απορρίφθηκε' = 35,
  'Εγκεκριμένη' = 40,
}
