import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UpdateField } from './../model/form-field.model';
import { FormsState } from './forms.state';
import { FORMS_STORE_KEY } from './forms.store.variable';
export const selectFormsFeature = createFeatureSelector<FormsState>(
  FORMS_STORE_KEY
);

export const selectUpdateFields = createSelector(
  selectFormsFeature,
  (state) => state.updateFields
);
export const selectToggleForModify = createSelector(
  selectFormsFeature,
  (state) => state.canToggleForModify
);
export const selectApproveUpdateFields = createSelector(
  selectFormsFeature,
  (state) => state.approveUpdateFields
);
export const selectApproveUpdateField = createSelector(
  selectApproveUpdateFields,
  (fields: UpdateField[], props: { formName: string; name?: string }) => {
    if (!fields.length) return null;
    let field: UpdateField;
    if (props.name) {
      field = fields.find(
        (f) => f.formName === props.formName && f.name === props.name
      );
    } else {
      field = fields.find((f) => f.formName === props.formName);
    }
    return field;
  }
);
export const select_comments = createSelector(
  selectFormsFeature,
  (state) => state.formComments
);
export const select_comments_position = createSelector(
  selectFormsFeature,
  (state) => state.commentsPosition
);
