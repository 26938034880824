export interface Stakod {
  StId: number;
  StCode: string;
  StTitle: string;
}
export interface StakodResponse {
  '@odata.context': string;
  value: Stakod[];
}
export class StakodViewModel {
  StId: number;
  StLabel: string;
  constructor(stakod: Stakod) {
    this.StId = stakod.StId;
    this.StLabel = (stakod.StCode + ' - ' + stakod.StTitle).trim();
  }
}
