import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ISnackBarData } from './snackbar';

@Injectable()
export class SnackBarService {
  private snackbarData: ISnackBarData = {
    message: '',
    actionText: 'Κλείσιμο',
    cssClass: [],
    duration: 4000,
  };
  private defaultCssClass = 'snackbar-success';
  constructor(public snackBar: MatSnackBar) {
    this.snackBar = snackBar;
  }
  private snackBarConfig(data: ISnackBarData) {
    const cssClasses = data.cssClass ? data.cssClass : [this.defaultCssClass];
    this.snackbarData = {
      ...this.snackbarData,
      ...data,
      cssClass: cssClasses,
    };
  }
  public openSnackbar(config: ISnackBarData) {
    this.snackBar.dismiss();
    this.snackBarConfig(config);
    this.snackBar.open(
      this.snackbarData.message,
      this.snackbarData.actionText,
      {
        horizontalPosition: 'center',
        announcementMessage: this.snackbarData.actionText,
        panelClass: [...this.snackbarData.cssClass],
        duration: this.snackbarData.duration,
      }
    );
  }
}
