import { FormArray, ValidatorFn } from '@angular/forms';
export function PercentSumValidator(controlName: string): ValidatorFn {
  return (formArray: FormArray): { [key: string]: boolean } | null => {
    const error = { percentsum: true };
    let sum = 0;
    if (!formArray.controls.length) return null;
    for (const form of formArray.controls) {
      sum += Number(form.get(controlName)?.value);
    }
    if (sum === 100) return null;
    return error;
  };
}
