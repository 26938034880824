import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'erbfactors-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  defaultStyles: Partial<CSSStyleDeclaration> = {
    padding: '0 1.5rem',
  };
  @Input() styles: Partial<CSSStyleDeclaration> = {};
  _styles: Partial<CSSStyleDeclaration>;
  year = new Date().getFullYear();
  ngOnInit() {
    this._styles = {
      ...this.defaultStyles,
      ...this.styles,
    };
  }
}
