import { ValidatorFn, AbstractControl } from '@angular/forms';


// export function WebsiteValidator(): ValidatorFn {
//   return (control: AbstractControl): { [key: string]: boolean } | null => {
//     const error = { website: true };
//     const value: string = control.value;
//     if (!value) return null;
//     // if (!value.startsWith('http://') && !value.startsWith('https://')) {
//     //   return error;
//     // }
//     //const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
//     if (!value.startsWith('www.') &&  !value.startsWith('http://www.') && !value.startsWith('https://www.')) {
//        return error;
//      }
//     const reg = '([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
//     const regex = new RegExp(reg);

//     if (!value) return null;
//     return regex.test(value) ? null : error;
//   };
// }

export function WebsiteValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const error = { website: true };
    const value: string = control.value;
    if (!value) return null;
    // if (!value.startsWith('http://') && !value.startsWith('https://')) {
    //   return error;
    // }
    //const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    if (!value.startsWith('www.') &&  !value.startsWith('http://www.') && !value.startsWith('https://www.') &&
        !value.startsWith('WWW.') &&  !value.startsWith('HTTP://WWW.') && !value.startsWith('HTTPS://WWW.') ) {
       return error;
     }
    const reg = '([\\A-Za-z.-]+)\\.([A-Za-z.]{2,6})[/\\w .-]*/?';
    const regex = new RegExp(reg);

    if (!value) return null;
    return regex.test(value) ? null : error;
  };
}

