<section
  fxFlex="grow"
  class="control-container"
  fxLayout="column"
  *ngIf="!field.templateOptions.hidden"
  [ngClass]="{
    'field-for-edit':
      (shouldApproveForEdit | async) || (forEdit && (canClickForEdit | async)),
    'cursor-pointer': (canClickForEdit | async)
  }"
  (click)="setForEdit()"
>
  <div fxLayout="row" fxLayoutAlign="start center">
    <label [for]="field.name" class="color-blue">
      {{ to.label }}
      <span
        *ngIf="field.templateOptions.required"
        [ngStyle]="{
          color: formControl.invalid ? 'red' : 'initial'
        }"
        >*</span
      >
    </label>
    <mat-icon
      *ngIf="to?.description"
      id="pop{{ key }}"
      (mouseenter)="showInfo = !showInfo"
      (mouseleave)="showInfo = !showInfo"
      class="color-blue onHover"
      >info</mat-icon
    >
    <dx-popover
      target="#pop{{ key }}"
      position="top"
      [width]="300"
      [(visible)]="showInfo"
      *ngIf="to?.description"
    >
      <div *dxTemplate="let data = model; of: 'content'">
        {{ to?.description }}
      </div>
    </dx-popover>
  </div>

  <mat-form-field appearance="outline" color="primary">
    <input
      matInput
      [type]="to.type"
      [name]="field.key"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [attr.data-cy]="field.templateOptions.attributes['data-cy']"
    />
    <mat-icon matSuffix *ngIf="to.addonRight?.text">{{
      to.addonRight.text
    }}</mat-icon>
  </mat-form-field>
  <mat-error>
    <formly-validation-message [field]="field"></formly-validation-message>
  </mat-error>
</section>
