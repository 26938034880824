import { is_factor } from './auth.selectors';
import { Action, createReducer, on } from '@ngrx/store';
import * as moment from 'moment';
import { USER_STORAGE_KEY } from './../auth.variable';
import * as AuthActions from './auth.actions';
import { AuthInitialState, AuthState } from './auth.state';
const _authReducer = createReducer(
  AuthInitialState,
  on(AuthActions.after_login, (state, action) => {
    const buyer_claim = action.UserView.UserClaims.find(
      (c) => c.Type === 'IsBuyer'
    );
    const is_buyer = !!Number(buyer_claim?.Value);
    const supplier_claim = action.UserView.UserClaims.find(
      (c) => c.Type === 'IsSupplier'
    );
    const is_supplier = !!Number(supplier_claim?.Value);
    const canViewReports_claim = action.UserView.UserClaims.find(
      (c) => c.Type === 'canViewReports'
    );
    const canViewReports = !!Number(canViewReports_claim?.Value);

    const newState: AuthState = {
      ...state,
      username: action.UserView.UserName,
      token: action.auth_token,
      expirationDate: moment(new Date()).add(action.expires, 'ms').toDate(),
      email: action.UserView.Email,
      userId: action.UserView.UserId,
      roles: [...action.UserView.UserRoles],
      claims: {
        is_buyer,
        is_supplier,
        canViewReports
      },
      mustResetPassword: action.mustResetPassword
    };
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(newState));
    return {
      ...newState,
    };
  }),
  on(AuthActions.from_cache, (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  }),
  on(AuthActions.set_available_companies, (state, action) => ({
    ...state,
    available_companies: [...action.companies],
  })),
  on(AuthActions.set_selected_company, (state, action) => ({
    ...state,
    companyId: action.companyId,
  })),
  on(AuthActions.set_roles, (state, action) => ({
    ...state,
    roles: [...action.roles],
  })),
  on(AuthActions.set_claims, (state, action) => ({
    ...state,
    claims: {
      ...state.claims,
      is_buyer: action.is_buyer,
      is_supplier: action.is_supplier,
    },
  })),
  on(AuthActions.logoutSuccess, (state) => {
    return { ...state, ...AuthInitialState };
  })
);
export function authReducer(state: AuthState | undefined, action: Action) {
  return _authReducer(state, action);
}
