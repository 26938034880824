<h1 mat-dialog-title>{{data.title}}</h1>
<section>
    <section mat-dialog-content>
        <p class="break-lines">{{data.description}}</p>
    </section>
    <section mat-dialog-actions fxLayout fxLayoutAlign="space-between stretch">
        <button mat-raised-button color="warn" [mat-dialog-close]="false">{{data.cancel_action_name}}</button>
        <button *ngIf="!!data.confirm_action_name" mat-raised-button color="accent" [mat-dialog-close]="true"
            cdkFocusInitial>{{data.confirm_action_name}}</button>
    </section>
</section>
