import { FormStatusEnum } from '@erbfactors/feature-company/shared';
import { createAction, props } from '@ngrx/store';
import { LegalFormOption } from './../model/legal-form.model';
import { FormMetata } from './company-shared.state';
export const set_legal_form = createAction(
  'SET_LEGAL_FORM',
  props<{ legalForm: LegalFormOption }>()
);
export const set_legal_forms = createAction(
  'SET_LEGAL_FORMS',
  props<{ legalForms: LegalFormOption[] }>()
);
export const fetch_legal_forms = createAction('FETCH_LEGAL_FORMS');
export const set_vrn_and_guid = createAction(
  'SET_VRN_AND_GUID',
  props<{ vrn: string; guid: string;}>()
);
export const set_buyer_Name = createAction(
  'SET_BUYER_NAME',
  props<{ buyerName: string;}>() //TARAS
);
export const patch_form_metadata = createAction(
  'PATCH_FORM_METADATA',
  props<{ metadata: Map<string, FormMetata> }>()
);
export const set_status = createAction(
  'SET_STATUS',
  props<{ status: FormStatusEnum; statusDescription: string }>()
);
export const reset_status = createAction('RESET_STATUS');
export const reset_company_shared_store = createAction(
  'RESET_COMPANY_SHARED_STORE'
);
