import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  EbAccountValidator,
  FaxValidator,
  MobileValidator,
  PhoneValidator,
  WebsiteValidator,
} from '@erbfactors/util-forms';
import { VRN_NUMBER_CONTROL_NAME } from '../company-form.variable';
export type CompanyInfoControlType =
  | 'VatNumber'
  | 'CompanyName'
  | 'DistinctiveTitle'
  | 'LegalForm'
  | 'AccessXaa'
  | 'ObjectOfWork'
  | 'CompanyAddress'
  | 'Zip'
  | 'TaxOffice'
  | 'Phone'
  | 'Mobile'
  | 'Fax'
  | 'WebSite'
  | 'Stakod'
  | 'ComBankAccount'
  | 'LocationId'
  | 'PrefectureId'
  | 'IsLocked'
  // | 'BuyerName'
  ;
export class CompanyInfo
  implements Record<CompanyInfoControlType, string | number | boolean> {
  ComId: number;
  VatNumber: string;
  CompanyName: string;
  DistinctiveTitle: string;
  LegalForm: number;
  AccessXaa: number;
  ObjectOfWork: string;
  CompanyAddress: string;
  Zip: string;
  TaxOffice: string;
  Phone: string;
  Mobile: string;
  Fax: string;
  WebSite: string;
  Stakod: number;
  ComBankAccount: string;
  LocationId: number;
  PrefectureId: number;
  IsLocked: boolean; //new field
  //BuyerName: string;
}
export class CompanyInfoView {
  ComId: number;
  VatNumber: string;
  CompanyName: string;
  DistinctiveTitle: string;
  LegalForm: number;
  AccessXaa: number;
  ObjectOfWork: string;
  CompanyAddress: string;
  Zip: string;
  TaxOffice: string;
  Phone: string;
  Mobile: string;
  Fax: string;
  WebSite: string;
  Stakod: number;
  ComBankAccount: string;
  LocationId: number;
  PrefectureId: number;
  IsLocked: boolean; //new field
  //BuyerName: string;
}



export const CompanyInfoControls: () => Record<
  CompanyInfoControlType,
  AbstractControl
> = () => ({
  ComId: new FormControl(null),
  VatNumber: new FormControl(null, Validators.required),
  CompanyName: new FormControl(null, Validators.required),
  DistinctiveTitle: new FormControl(),
  LegalForm: new FormControl(null, Validators.required),
  AccessXaa: new FormControl(),
  ObjectOfWork: new FormControl(null, Validators.required),
  CompanyAddress: new FormControl(null, Validators.required),
  Zip: new FormControl(),
  TaxOffice: new FormControl(null, Validators.required),
  Phone: new FormControl(null, [Validators.required, PhoneValidator()]),
  Mobile: new FormControl(null, [Validators.required, MobileValidator()]),
  Fax: new FormControl(null, [FaxValidator()]),
  WebSite: new FormControl(null, [WebsiteValidator()]),
  Stakod: new FormControl(null, Validators.required),
  ComBankAccount: new FormControl(null, [EbAccountValidator()]),
  LocationId: new FormControl(null, Validators.required),
  PrefectureId: new FormControl(null, Validators.required),
  IsLocked: new FormControl(null)
});
export class CompanyInfoFormGroup extends FormGroup {
  controls: Record<CompanyInfoControlType, AbstractControl>;
  constructor(_controls: Record<CompanyInfoControlType, AbstractControl>) {
    super(_controls);
  }
  patchVrn(vrn: string) {
    this.get(VRN_NUMBER_CONTROL_NAME).patchValue(vrn, { emitEvent: false });
  }
}
