import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs/operators';
import { CompanySharedApiService } from '../api/company-shared-api.service';
import * as CompanySharedActions from './company-shared.actions';
@Injectable()
export class CompanySharedEffects {
  constructor(
    private actions$: Actions,
    private api: CompanySharedApiService
  ) {}
  fetch_legal_forms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanySharedActions.fetch_legal_forms),
      switchMap(() => this.api.fetch_legal_forms()),
      tap((res) => {
        console.log(res);
      }),
      map((legalForms) => CompanySharedActions.set_legal_forms({ legalForms }))
    );
  });
}
