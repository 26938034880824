import { Action, createReducer, on } from '@ngrx/store';
import * as LayoutActions from './layout.actions';
import {
  DEFAULT_LOADING_SCREEN_MESSAGE,
  initialLayoutState,
  LayoutState,
} from './layout.state';

const _layoutReducer = createReducer(
  initialLayoutState,
  on(LayoutActions.SetLoadingScreen, (state, action) => {
    return {
      ...state,
      loadingScreen: true,
      loadingScreenMessage: action.message || DEFAULT_LOADING_SCREEN_MESSAGE,
    };
  }),
  on(LayoutActions.CloseLoadingScreen, (state) => ({
    ...state,
    loadingScreen: false,
  })),
  on(LayoutActions.ToggleMenu, (state) => ({
    ...state,
    menuOpened: !state.menuOpened,
  })),
  on(LayoutActions.SetPageTitle, (state, action) => ({
    ...state,
    pageTitle: action.title,
  })),
  on(LayoutActions.reset_loading_message, (state) => ({
    ...state,
    loadingScreenMessage: DEFAULT_LOADING_SCREEN_MESSAGE,
  }))
);
export function layoutReducer(state: LayoutState | undefined, action: Action) {
  return _layoutReducer(state, action);
}
