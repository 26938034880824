import { LegalFormOption } from './../model/legal-form.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { COMPANY_SHARED_STORE_KEY } from '../company-form.variable';
import { CompanySharedState } from './company-shared.state';
export const selectCompanySharedState =
  createFeatureSelector<CompanySharedState>(COMPANY_SHARED_STORE_KEY);
export const selectLegalForm = createSelector(
  selectCompanySharedState,
  (state) => state.legalForm
);
export const selectVrn = createSelector(
  selectCompanySharedState,
  (state) => state.vrn
);
// export const selectbuyerName = createSelector(
//   selectCompanySharedState,
//   (state) => state.buyerName //TARAS
// );
export const selectGuid = createSelector(
  selectCompanySharedState,
  (state) => state.guid
);
export const selectGuidAndVrn = createSelector(
  selectCompanySharedState,
  (state) => ({
    guid: state.guid,
    vrn: state.vrn,
  })
);
export const selectLegalForms = createSelector(
  selectCompanySharedState,
  (state) => state.legalForms
);
export const selectMetadataMap = createSelector(
  selectCompanySharedState,
  (state) => state.formMetadata
);
export const select_status = createSelector(
  selectCompanySharedState,
  (state) => state.status
);
export const select_legal_form_by_id = createSelector(
  selectLegalForms,
  (legalForms: LegalFormOption[], props: { legalFormId: number }) => {
    const legalForm = legalForms.find((lf) => lf.LfId === props.legalFormId);
    return legalForm;
  }
);
