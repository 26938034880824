import { CompanySharedApiService } from './api/company-shared-api.service';
import { CompanySharedEffects } from './store/company-shared.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { COMPANY_SHARED_STORE_KEY } from './company-form.variable';
import { company_shared_reducer } from './store/company-shared.reducer';
import { CompanyFormService } from './services';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(COMPANY_SHARED_STORE_KEY, company_shared_reducer),
    EffectsModule.forFeature([CompanySharedEffects]),
  ],
  providers: [CompanySharedApiService, CompanyFormService],
})
export class FeatureCompanySharedModule {}
