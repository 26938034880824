import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@erbfactors/util-auth';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'user-activation',
    loadChildren: () =>
      import('@erbfactors/feature-user-activation').then((m) => m.FeatureUserActivationModule),
    data: { animation: 'LoginPage' },
  },
  {
    path: 'user-activation/:id',
    loadChildren: () =>
      import('@erbfactors/feature-user-activation').then((m) => m.FeatureUserActivationModule),
    data: { animation: 'LoginPage' },
  },
  {
    path: 'login',
    loadChildren: () =>
      import('@erbfactors/feature-login').then((m) => m.FeatureLoginModule),
    data: { animation: 'LoginPage' },
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('@erbfactors/feature-forgot-password').then((m) => m.FeatureForgotPasswordModule),
    data: { animation: 'LoginPage' },
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('@erbfactors/feature-reset-password').then((m) => m.FeatureResetPasswordModule),
    data: { animation: 'LoginPage' },
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('@erbfactors/feature-dashboard').then(
        (m) => m.FeatureDashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'supplierinvitation',
    loadChildren: () =>
      import('@erbfactors/feature-supplier-invitation').then(
        (m) => m.FeatureSupplierInvitationModule
      ),
  },
  {
    path: '**',
    redirectTo: `/supplierinvitation/noform`,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
