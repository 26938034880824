<section
  fxFlex='grow'
  class='control-container'
  fxLayout='column'
  *ngIf='!field.templateOptions.hidden'
  [ngClass]="{
    'field-for-edit':
      (shouldApproveForEdit | async) || (forEdit && (canClickForEdit | async)),
    'cursor-pointer': (canClickForEdit | async)
  }"
  (click)='setForEdit()'
>
  <label [for]='field.name' class='color-blue'>
    {{ to.label }}
    <span
      *ngIf='field.templateOptions.required'
      [ngStyle]="{
        color: formControl.invalid ? 'red' : 'initial'
      }"
    >*</span
    >
  </label>
  <mat-form-field appearance='outline' color='primary'>
    <input
      matInput
      [type]='to.type'
      [name]='field.key'
      [formControl]='formControl'
      [attr.data-cy]="field.templateOptions.attributes['data-cy']"
      [matAutocomplete]='auto'
    />
    <mat-icon matSuffix *ngIf='to.addonRight?.text'>{{
      to.addonRight.text
      }}</mat-icon>
  </mat-form-field>
  <mat-autocomplete #auto='matAutocomplete'>
    <mat-option *ngFor='let value of filter | async' [value]='value[to.labelProp]'>
      {{value[to.labelProp]}}
    </mat-option>
  </mat-autocomplete>
  <mat-error>
    <formly-validation-message [field]='field'></formly-validation-message>
  </mat-error>
</section>

