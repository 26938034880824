import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { fadeAnimation } from '@erbfactors/shared-ui';
import { isControlRequired } from '../../../helpers/is-control-required';
import { TextField } from './../../../model/form-field.model';

@Component({
  selector: 'erbfactors-text-control',
  templateUrl: './text-control.component.html',
  styleUrls: ['./text-control.component.scss'],
  animations: [fadeAnimation],
})
export class TextControlComponent implements OnInit {
  @Input() errors: { name: string; message: string }[] = [];
  @Input() showErrors = true;
  @Input() field: TextField;
  @Input() control: AbstractControl;
  @Input() form: FormGroup;
  required: boolean;
  styles: Partial<CSSStyleDeclaration>;
  showInfo: boolean = false;
  ngOnInit() {
    this.styles = {
      display: 'flex',
      flexDirection: 'column',
      ...this.field.styles,
    };
    this.required = isControlRequired(this.control);
    if (this.required) {
      this.errors.push({
        name: 'required',
        message: 'Το πεδίο είναι υποχρεωτικό.',
      });
    }
  }
}
