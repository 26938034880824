import { Store } from '@ngrx/store';
import { FieldType } from '@ngx-formly/core';
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import {
  filter,
  first,
  map,
  mergeMap,
  scan,
  switchMap,
  tap,
} from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import {
  selectApproveUpdateField,
  selectToggleForModify,
  remove_update_field,
  add_update_field,
} from '../../../store';
@Component({
  selector: 'erbfactors-formly-ng-datepicker',
  templateUrl: './formly-ng-datepicker.component.html',
  styleUrls: ['./formly-ng-datepicker.component.scss'],
})
export class FormlyNgDatepickerComponent extends FieldType implements OnInit {
  canClickForEdit: Observable<boolean>;
  shouldApproveForEdit: Observable<boolean>;
  holidayDays: string[];
  forEdit = false;
  formControl: FormControl;
  options$: Observable<string[]>;
  guid: string;
  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.options$ = this.to.options as Observable<string[]>;
    if (this.options$)
      this.options$.subscribe((date) => (this.holidayDays = date));

    this.canClickForEdit = this.store.select(selectToggleForModify);
    this.shouldApproveForEdit = this.store
      .select(selectApproveUpdateField, {
        formName: this.options.formState.name,
        name: this.field.key as string,
      })
      .pipe(map((field) => !!field));
  }

  holidayFilter = (d: Date): boolean => {
    const calendar = new Date(d).toString();
    return !this.holidayDays?.find((x) => {
      const date = new Date(x).toString();
      return date === calendar;
    });
  };
  setForEdit() {
    this.canClickForEdit
      .pipe(
        first(),
        tap((bool) => {
          if (bool) {
            this.forEdit = !this.forEdit;
            if (!this.guid) this.guid = uuid();
            if (this.forEdit) {
              this.store.dispatch(
                add_update_field({
                  field: {
                    guid: this.guid,
                    name: <string>this.field.key,
                    formName: this.options.formState.name,
                  },
                })
              );
            } else {
              this.store.dispatch(remove_update_field({ guid: this.guid }));
            }
          }
        })
      )
      .subscribe();
  }
}
