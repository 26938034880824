import { AbstractControl, ValidatorFn } from '@angular/forms';
export function PercentageValidator():  ValidatorFn {
  return (control: AbstractControl) => {
    const value = Number(control.value);
    const error = { percent: true };
    if (isPercent(value)) {
      return null;
    } else {
      return error;
    }
  };
}
export function isPercent(value: number): boolean {
  if (!value) return true;
  if (value > 100) return false;
  const precisionValue = Number(value.toFixed(2));
  return precisionValue === value;
}
