import { ValidatorFn, AbstractControl } from '@angular/forms';

export function IBANValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const error = { iban: true };
    const originIban = control.value;
    if (!originIban) return null;
    const ibanLen = originIban.length;
    const bankCode = originIban.substr(4, 3);
    if (bankCode !== '029') {
      const bankAccount = originIban.substr(11, ibanLen);
    } else {
      const bankAccount = '000000' + originIban.substr(11, ibanLen);
    }
    const branch = originIban.substr(7, 4);
    let tempIban = originIban.substr(4, ibanLen) + originIban.substr(0, 4);
    tempIban = tempIban.replace('G', '16').replace('R', '27');
    const integerIban = mod97(tempIban);
    return integerIban === 1 ? null : error;
  };

  function mod97(digital: number | string) {
    digital = digital.toString();
    let checksum: number | string = digital.slice(0, 2);
    let fragment = '';
    for (let offset = 2; offset < digital.length; offset += 7) {
      fragment = checksum + digital.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
  }

}

