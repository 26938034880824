import { DxValidationRule } from '../model';
import { isPercent, vatValidation, vrnValidator } from '../validators';
import { userPhoneValidator } from '../validators/user-phone.validator';

export function RequiredValidation(): DxValidationRule {
  return {
    type: 'required',
    message: 'Το πεδίο είναι υποχρεωτικό',
  };
}
export function EmailValidation(): DxValidationRule {
  return {
    type: 'email',
    message: 'Μη έγκυρο email.',
  };
}
export function PercentValidation(): DxValidationRule {
  return {
    type: 'custom',
    message: 'Μη αποδεκτός αριθμός ποσοστού',
    callback: (params) => {
      return isPercent(params.value);
    },
  };
}
export function VrnValidation(): DxValidationRule {
  return {
    type: 'custom',
    message: 'Μη έγκρυο Α.Φ.Μ',
    callback: (params) => {
      if(params.data.IsForeignVatNumber === '0'){
        return !vrnValidator(params.value);
      }else if(params.data.IsForeignVatNumber === '1') {
        return !vatValidation(params.value)
      }else return true;
      
    },
  };
}
export function UserPhoneValidation(): DxValidationRule {
  return {
    type: 'custom',
    message: 'phone false',
    callback: (params) => {
      return !userPhoneValidator(params.value);
    },
  };
}
