<section
  fxFlex="grow"
  class="control-container"
  fxLayout="column"
  *ngIf="!field.templateOptions.hidden"
  [ngClass]="{
    'field-for-edit':
      (shouldApproveForEdit | async) || (forEdit && (canClickForEdit | async)),
    'cursor-pointer': (canClickForEdit | async)
  }"
  (click)="setForEdit()"
>
  <label [for]="field.name" class="color-blue">
    {{ to.label }}
    <span
      *ngIf="field.templateOptions.required"
      [ngStyle]="{
        color: formControl.invalid ? 'red' : 'initial'
      }"
      >*</span
    >
  </label>
  <mat-form-field appearance="outline" color="primary">
    <input
      matInput
      [min]="to.minDate"
      [matDatepicker]="picker"
      [matDatepickerFilter]="holidayFilter"
      [formControl]="formControl"
      [name]="field.key"
      [type]="to.type"
      [readonly]="true"
      [attr.data-cy]="field.templateOptions.attributes['data-cy']"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-icon matSuffix *ngIf="to.addonRight?.text">{{
      to.addonRight.text
    }}</mat-icon>
  </mat-form-field>
  <mat-error>
    <formly-validation-message [field]="field"></formly-validation-message>
  </mat-error>
</section>
