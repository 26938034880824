import { Action, createReducer, on } from '@ngrx/store';
import * as FormActions from './forms.actions';
import { FormsInitialState, FormsState } from './forms.state';
const _forms_reducer = createReducer(
  FormsInitialState,

  on(FormActions.add_update_field, (state, action) => {
    const field = {
      formName: action.field.formName,
      name: action.field.name,
      guid: action.field.guid,
    };
    return { ...state, updateFields: [...state.updateFields, field] };
  }),
  on(FormActions.remove_update_field, (state, action) => {
    return {
      ...state,
      updateFields: [
        ...state.updateFields.filter((field) => field.guid !== action.guid),
      ],
    };
  }),
  on(FormActions.reset_forms_store, (state) => ({
    ...state,
    canSelectFieldsForMod: null,
    updateFields: [],
    approveUpdateFields: [],
  })),
  on(FormActions.set_toggle_for_modify, (state, action) => ({
    ...state,
    canToggleForModify: action.toogle,
  })),
  on(FormActions.set_approve_update_fields, (state, action) => ({
    ...state,
    approveUpdateFields: [...action.fields],
  })),
  on(FormActions.clear_update_fields, (state) => ({
    ...state,
    updateFields: [],
    approveUpdateFields: [],
  })),
  on(FormActions.set_comments, (state, action) => ({
    ...state,
    formComments: action.comments,
  })),
  on(FormActions.reset_commnents, (state) => ({
    ...state,
    formComments: null,
    commentsPosition: null,
  })),
  on(FormActions.set_comments_position, (state, action) => ({
    ...state,
    commentsPosition: action.position,
  }))
);
export function forms_reducer(state: FormsState, action: Action) {
  return _forms_reducer(state, action);
}
