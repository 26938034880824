import { Injectable } from '@angular/core';

@Injectable()
export class CompanyFormService {

  private _companiesBindError = '';
  public get companiesBindError() {
    return this._companiesBindError;
  }
  public set companiesBindError(value) {
    this._companiesBindError = value;
  }

  private _shareholdersError = '';
  public get shareholdersError() {
    return this._shareholdersError;
  }
  public set shareholdersError(value) {
    this._shareholdersError = value;
  }

  private _adminUsersError = '';
  public get adminUsersError() {
    return this._adminUsersError;
  }
  public set adminUsersError(value) {
    this._adminUsersError = value;
  }

  // constructor() {}

}
