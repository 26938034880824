<h2 mat-dialog-title>Κωδικός Μίας Χρήσης (OTP)</h2>
<mat-dialog-content class="mat-typography">
  <section fxLayout="column">
    <h4>Παρακαλούμε συμπληρώστε τον Κωδικό που θα λάβετε στο email σας.</h4>
    <form [formGroup]="form">
      <mat-form-field>
        <input
          matInput
          cdkFocusInitial
          #optInput
          type="text"
          formControlName="otp"
        />
      </mat-form-field>
    </form>
  </section>
</mat-dialog-content>
<section mat-dialog-actions fxLayout fxLayoutAlign="space-between stretch">
  <button
    mat-raised-button
    color="warn"
    [mat-dialog-close]="false"
    (click)="close(false)"
  >
    Ακύρωση
  </button>
  <button mat-raised-button color="accent" (click)="close(true)">
    Επιβεβαίωση
  </button>
</section>
