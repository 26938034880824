import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FieldType } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import {
  add_update_field,
  remove_update_field
} from './../../../store/forms.actions';
import {
  selectApproveUpdateField,
  selectToggleForModify
} from './../../../store/forms.selectors';
@Component({
  selector: 'erbfactors-formly-ng-select',
  templateUrl: './formly-ng-select.component.html',
  styleUrls: ['./formly-ng-select.component.scss'],
})
export class FormlyNgSelectComponent extends FieldType implements OnInit {
  canClickForEdit: Observable<boolean>;
  shouldApproveForEdit: Observable<boolean>;

  guid: string;
  forEdit = false;
  formControl: FormControl;
  options$: Observable<unknown[]>;
  constructor(private store: Store) {
    super();
  }
  ngOnInit() {
    this.options$ = this.to.options as Observable<unknown[]>;
    this.canClickForEdit = this.store.select(selectToggleForModify);
    this.shouldApproveForEdit = this.store
      .select(selectApproveUpdateField, {
        formName: this.options.formState.name,
        name: this.field.key as string,
      })
      .pipe(map((field) => !!field));
  }
  setForEdit() {
    this.canClickForEdit
      .pipe(
        first(),
        tap((bool) => {
          if (bool) {
            this.forEdit = !this.forEdit;
            if (!this.guid) this.guid = uuid();
            if (this.forEdit) {
              this.store.dispatch(
                add_update_field({
                  field: {
                    guid: this.guid,
                    name: <string>this.field.key,
                    formName: this.options.formState.name,
                  },
                })
              );
            } else {
              this.store.dispatch(remove_update_field({ guid: this.guid }));
            }
          }
        })
      )
      .subscribe();
  }
}
