import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StoreModule } from '@ngrx/store';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { ConfirmDialogComponent } from './components/confirm-dialog';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { CurrencyFormatterPipe, SafePipe } from './pipes';
import { SnackBarService } from './services';
import * as fromLayout from './store/layout/layout.reducer';
import { LAYOYT_STORE_KEY } from './store/share-store.variable';
import { OtpDialogComponent } from './components/otp-dialog/otp-dialog.component';
import { HeaderComponent } from './components/header/header.component';
import { PasswordHintComponent } from './components/password-hint/passoword-hint.component';
import { CookiesAlertComponent } from './components';
import {
  TermsModalComponent,
  TermsModalContainerComponent,
} from './components/terms-modal/terms-modal.component';
import { ScrollToEndDirective } from './directives/scroll-to-end.directive';

const devExpressModules = [DxDataGridModule];

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SafePipe,
    CurrencyFormatterPipe,
    LoadingScreenComponent,
    ConfirmDialogComponent,
    OtpDialogComponent,
    PasswordHintComponent,
    CookiesAlertComponent,
    TermsModalComponent,
    TermsModalContainerComponent,
    ScrollToEndDirective,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ...devExpressModules,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    StoreModule.forFeature(LAYOYT_STORE_KEY, fromLayout.layoutReducer),
    MatButtonModule,
  ],
  providers: [SnackBarService],
  exports: [
    PasswordHintComponent,
    FooterComponent,
    HeaderComponent,
    CookiesAlertComponent,
    TermsModalContainerComponent,
    LoadingScreenComponent,
    SafePipe,
    CurrencyFormatterPipe,
  ],
})
export class SharedUiModule {}
