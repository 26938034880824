import {
  FormArray,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroup,
} from '@angular/forms';
import {
  FormField,
  GreekVrnValidator,
  RequiredValidation,
  VrnValidation
} from '@erbfactors/util-forms';
import { of } from 'rxjs';
export interface CompaniesBindSignature {
  CbsId: number;
  ComComId: number;
  CbsVrn: string;
  CbsFullname: string;
  CbsFatherName: string;
  CbsIdentityNo: string;
  CbsAddress: string;
  IsForeignVatNumber:any;
}
export type CompaniesBindSignatureControlType =
  | 'CbsFullname'
  | 'CbsFatherName'
  | 'IsForeignVatNumber'
  | 'CbsVrn'
  | 'CbsIdentityNo'
  | 'CbsAddress'
  | 'CbsId'
  | 'ComComId';
export const CompaniesBindSignatureFields: FormField[] = [
  {
    displayName: 'Ονοματεπώνυμο',
    name: 'CbsFullname',
    type: 'text',
    validations: [RequiredValidation()],
    validators: [Validators.required],
  },
  {
    displayName: 'Πατρώνυμο',
    name: 'CbsFatherName',
    type: 'text',
    validations: [RequiredValidation()],
    validators:  [Validators.required],
    // validations: [],
    // validators:  [],
  },
  {
    displayName: 'Εθνικότητα ΑΦΜ',
    name: 'IsForeignVatNumber',
    type: 'select',
    valueField: 'value',
    displayField: 'displayValue',

    options: of([
      {
        value: "0",
        displayValue: 'Ελληνικό',
      },
      {
        value: "1",
        displayValue: 'Αλλοδαπό',
      },
    ]),
    defaultValue:"0",
    validators: [Validators.required],
    validations: [RequiredValidation()],
  },
  {
    displayName: 'ΑΦΜ',
    name: 'CbsVrn',
    type: 'text',
    validators: [Validators.required, GreekVrnValidator()],
    validations: [RequiredValidation(), VrnValidation()],
  },
  {
    displayName: 'Aριθ. Δελτίου Ταυτότητας',
    name: 'CbsIdentityNo',
    type: 'text',
    validators: [Validators.required],
    validations: [RequiredValidation()],
  },
  {
    displayName: 'Δ/νση Κατοικίας',
    name: 'CbsAddress',
    type: 'text',
    validators: [Validators.required],
    validations: [RequiredValidation()],
    placeholder: 'Οδός, αριθμός, Πόλη και ΤΚ',
  },
  {
    displayName: '',
    name: 'CbsId',
    type: 'text',
    hidden: true,
    defaultValue: 0,
    validators: [],
  },
  {
    displayName: '',
    name: 'ComComId',
    type: 'text',
    hidden: true,
    defaultValue: 0,
    validators: [],
  },
];

export const CompaniesBindSignatureControls: (
  data?: CompaniesBindSignature
) => Record<CompaniesBindSignatureControlType, AbstractControl> = (
  data?: CompaniesBindSignature
) => ({
  CbsFullname: new FormControl(data?.CbsFullname, Validators.required),
  CbsFatherName: new FormControl(data?.CbsFatherName, Validators.required),
  IsForeignVatNumber :new FormControl(data?.IsForeignVatNumber, Validators.required),
  CbsVrn: new FormControl(data?.CbsVrn, [GreekVrnValidator]),
  CbsIdentityNo: new FormControl(data?.CbsIdentityNo, Validators.required),
  CbsAddress: new FormControl(data?.CbsAddress, Validators.required),
  CbsId: new FormControl(data?.CbsId),
  ComComId: new FormControl(data?.ComComId),
});
export class CompaniesBindFormArray extends FormArray {
  constructor(
    controls: AbstractControl[] = [],
    validators: ValidatorFn[] = []
  ) {
    super(controls, validators);
  }
  clear() {
    while (this.controls.length !== 0) {
      this.removeAt(0);
    }
  }
  patch(cbs: CompaniesBindSignature[]) {
    for (let i = 0; i < cbs.length; i++) {
      const group = new FormGroup({
        ...CompaniesBindSignatureControls(cbs[i]),
      });
      if (this.disabled) {
        group.disable();
      }
      this.push(group);
    }
  }
}
