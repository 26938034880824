import { UpdateField } from '../model';
export type CommentsPositionType = 'top' | 'bottom';

export interface FormsState {
  canToggleForModify: boolean;
  updateFields: UpdateField[];
  approveUpdateFields: UpdateField[];
  formComments: string;
  commentsPosition: CommentsPositionType;
}

export const FormsInitialState: FormsState = {
  canToggleForModify: null,
  updateFields: [],
  approveUpdateFields: [],
  formComments: null,
  commentsPosition: null,
};
