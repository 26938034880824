<section
  class="color-blue control-container"
  [ngStyle]="styles"
  [formGroup]="form"
>
  <div fxLayout="row" fxLayoutAlign="start center">
    <label [for]="field.name" class="color-blue">
      {{ field.displayName }}
      <span
        *ngIf="required"
        [ngStyle]="{
          color:
            control.hasError('required') && control.touched ? 'red' : 'initial'
        }"
        >*</span
      >
    </label>

    <mat-icon
      *ngIf="field?.description"
      id="pop{{ field.name }}"
      (mouseenter)="showInfo = !showInfo"
      (mouseleave)="showInfo = !showInfo"
      class="color-blue onHover"
      >info</mat-icon
    >
    <dx-popover
      target="#pop{{ field.name }}"
      position="top"
      [width]="300"
      [(visible)]="showInfo"
      *ngIf="field?.description"
    >
      <div *dxTemplate="let data = model; of: 'content'">
        {{ field?.description }}
      </div>
    </dx-popover>
  </div>

  <mat-form-field appearance="outline" color="primary" fxFlex="grow">
    <input
      matInput
      type="text"
      [attr.data-cy]="field.name"
      [name]="field.name"
      [formControlName]="field.name"
      [placeholder]="field?.placeholder"
    />
  </mat-form-field>
  <section *ngFor="let error of errors">
    <mat-error
      [@fadeInOut]
      *ngIf="showErrors && control.hasError(error.name) && control.touched"
      >{{ error.message }}</mat-error
    >
  </section>
</section>
