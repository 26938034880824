import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
export enum FileType {
  Balance = 1,
  CopyOfId,
  Receipt,
}
export interface FileDto {
  CfFileType: FileType;
  CfFilename: string;
  CfFilepath: string;
  CfGuid: string;
  CfId: number;
  FsFsId: number;
  IcIcId: number;
}
export const AttachmentControls = (data?: FileDto) => ({
  CfFileType: new FormControl(data?.CfFileType),
  CfFilename: new FormControl(data?.CfFilename),
  CfFilepath: new FormControl(data?.CfFilepath),
  CfGuid: new FormControl(data?.CfGuid),
  CfId: new FormControl(data?.CfId),
  FsFsId: new FormControl(data?.FsFsId),
  IcIcId: new FormControl(data?.IcIcId),
});
export class AttachmentsFormArray extends FormArray {
  controls: AbstractControl[];
  inPreview = false;
  constructor(
    _controls: AbstractControl[] = [],
    validators: ValidatorFn[] = []
  ) {
    super(_controls, validators);
  }
  clear() {
    while (this.controls.length !== 0) {
      this.removeAt(0);
    }
  }
  patch(attachments: FileDto[]) {
    attachments.forEach((attachment) => {
      const newAttachment = new FormGroup({
        ...AttachmentControls(attachment),
      });
      if (this.disabled) {
        newAttachment.disable();
      }
      this.push(newAttachment);
    });
  }
}
