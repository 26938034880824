import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { delay, mapTo, switchMap, tap } from 'rxjs/operators';
import { reset_loading_message } from '../../store';
import {
  selectLoadingMessage,
  selectLoadingScreen
} from './../../store/layout/layout.selectors';

@Component({
  selector: 'erbfactors-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
})
export class LoadingScreenComponent implements OnInit {
  constructor(private store: Store) {}
  showOverlay$: Observable<boolean>;
  message$: Observable<string>;
  ngOnInit() {
    this.showOverlay$ = this.store.select(selectLoadingScreen).pipe(
      tap((bool) => console.log('Screen Is', bool)),
      switchMap((bool) =>
        bool
          ? of(bool)
          : of(null).pipe(
              delay(500),
              tap(() => this.store.dispatch(reset_loading_message())),
              mapTo(bool)
            )
      )
    );
    this.message$ = this.store.select(selectLoadingMessage);
  }
}
