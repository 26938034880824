import { Shareholder } from './../../../../../feature-company/shared/src/lib/model/shareholders.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { catchError, take, tap } from 'rxjs/operators';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CloseLoadingScreen, SetLoadingScreen, SnackBarService } from '@erbfactors/shared-ui';
import { EMPTY, Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { FormField } from '../../model/form-field.model';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'erbfactors-form-grid',
  templateUrl: './form-grid.component.html',
  styleUrls: ['./form-grid.component.scss'],
})
export class FormGridComponent implements OnInit {
  @Input() cy: string;
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() fields: FormField[];
  @Input() gridForm: FormArray;
  @Input() key: string;
  @Input() itemsMaxLength: number = 0;
  @Output() afterDelete: EventEmitter<any> = new EventEmitter();
  @Output() afterAdd: EventEmitter<any> = new EventEmitter();
  @Output() afterUpdate: EventEmitter<any> = new EventEmitter();
  @Input() loading: Observable<boolean>;
  @Input() center_grid = true;
  @Input() checkIfVatHasInvitation: boolean = false;
  inline_form_align = 'start stretch';
  inlineForm: FormGroup;
  visibleFields: FormField[];
  selectedIndex: number;
  gridValues: Observable<unknown[]>;
  constructor(private snackbar: SnackBarService, private router: Router, private http: HttpClient,) {
    this.allowEditAdmin = this.allowEditAdmin.bind(this);
  }
  ngOnInit(): void {
    this.inlineForm = new FormGroup(this.newControls);
    this.inlineForm.valueChanges.subscribe(() => console.log(this.inlineForm));
    this.visibleFields = this.fields.filter((field) => !field.hidden);
    this.gridValues = this.gridForm.valueChanges.pipe(
      startWith(this.gridForm.value)
    );
    if (this.center_grid) {
      this.inline_form_align = 'center stretch';
    }
  }
  async addToForm() {
    const formIndex = this.key;
    const data = this.inlineForm.value;
    const index = (this.gridForm.value as Array<unknown>).findIndex(
      (obj) => obj[this.key] === data[this.key]
    );
    if(this.checkIfVatHasInvitation && await this.hasVatAlreadyInvitedByCompany(data[this.key])){
      return;
    }
    //if (index >= 0) {
    if (false) {
      this.snackbar.openSnackbar({
        message: `Υπάρχει ήδη καταχώρηση με το ΑΦΜ ${data[this.key]}`,
        //message: `Υπάρχει ήδη καταχώρηση με το ${this.key} = ${data[this.key]}`,
      });
    } else {
      if (
        this.itemsMaxLength &&
        this.gridForm.value?.length >= this.itemsMaxLength
      ) {
        this.snackbar.openSnackbar({
          message: `Δεν επιτρέπεται η προσθήκη νέων γραμμών στον Πίνακα. Μέγιστος αριθμός γραμμών = ${this.itemsMaxLength}`,
          cssClass: ['snackbar-error'],
        });
      } else {
        this.gridForm.push(this.inlineForm);
        this.afterAdd.emit(this.inlineForm.getRawValue());
        this.inlineForm = new FormGroup({ ...this.newControls });
      }
    }
  }

  private async hasVatAlreadyInvitedByCompany(vat: string) : Promise<boolean> {
    let hasVatInvitation = false;

    const url = `${'api/InvitationCompany/CheckIfVatHasInvitation'}/${vat}`;
    await this.http
    .get(url)
    .pipe(
      take(1),
      tap((response : boolean) => {        
        if (response) {
          this.snackbar.openSnackbar({
            message: `Υφίσταται ήδη πρόσκληση προς το ΑΦΜ ${vat}. Παρακαλούμε όπως επισκεφτείτε το μενού Επισκόπηση Προσκλήσεων.`,
            cssClass: ['snackbar-error']
          });
        }

        hasVatInvitation = response;
      }),
    )
    .toPromise();
    
    return hasVatInvitation;
  }
  
  private get newControls(): { [key: string]: FormControl } {
    const controls: { [key: string]: FormControl } = {};
    for (const field of this.fields) {
      console.log('fields', field)
      controls[field.name as string] = new FormControl(field.defaultValue, [
        ...field.validators,
      ]);
    }
    return controls;
  }

  async onRowUpdating(event) {
    event.cancel = Promise.all([]).then(async values => {
      if(this.checkIfVatHasInvitation && await this.hasVatAlreadyInvitedByCompany(event.newData[this.key])) {
        return true;
      } else {
        return false;
      }
    });
  }

  
  updated(event) {
    //const a = event.data as Shareholder[];
    //console.log("VAL = " + event.data['CsPercentage']);
    //CsPercentage
    // const grid = this.gridForm;
    // //const gridRows = this.gridForm.value;
    // if(this.gridForm.value != null)
    // {
    //   const array = this.gridForm.value
    //   for(let i = 0; array.length; i++){
    //       let item = array[i];
    //   }
    // }
    // const rows = this.fields; 
    //
    if (!this.key) throw new Error('No key on grid. Failed to update row');
    console.log('updated', event);
    const data = event.data;
    const index = (this.gridForm.controls as Array<unknown>).findIndex(
      (control: FormControl) => {
        return control.value[this.key] === data[this.key];
      }
    );
    this.gridForm.at(index).patchValue(data);
    //this.gridForm.at(index).patchValue(data, {emitEvent: true});
    // this.gridForm.at(index).patchValue(data, {emitEvent: true, onlySelf: true});

     this.afterUpdate.emit(data);
  }

  removed(event) {
    if (!this.key) throw new Error('No key on grid. Failed to delete row');
    console.log('deleted', event);
    const data = event.data;
    const index = (this.gridForm.controls as Array<unknown>).findIndex(
      (control: FormControl) => {
        return control.value[this.key] === data[this.key];
      }
    );
    if (index >= 0) {
      setTimeout(() => {
        this.gridForm.removeAt(index);
      });

      console.log('removed', event);
    } else {
      console.warn('index < 0', data, this.key);
    }
    this.afterDelete.emit(data);
  }
  // getDxWidth(field: FormField): string {
  //   if (field.styles && field.styles.width) {
  //     const width = field.styles.width;
  //     if (width.includes('px')) {
  //       const numWidth = Number(width.split('p')[0]);
  //       return `${numWidth + 16}px`;
  //     }
  //     return width;
  //   }
  //   return 'auto';
  // }

  allowEditAdmin(e): boolean {
    if (!this.router.url.includes('supplierinvitation/')) {
      if(e.row.data.IsAdmin || e.row.data.UserRole === 1) {
        return this.canEdit;

      } else { return true; }

    } else { return true; }
  }
  
  resizePopupWidth(e) {
    if (
      (e.parentType === 'dataRow' && e.dataField === 'UserRole') ||
      (this.router.url.includes('supplierinvitation/') && e.editorName === 'dxSelectBox')
    ) {
      e.editorOptions.dropDownOptions = {
        minWidth: '140px',
      };
    }
  }

  /* Display proper role in users grid
  *  where label is missing
  */
  displayRoles(element, cell) {
    let cellText = cell.text;
    if (cell.column.dataField === 'UserRole' && !cellText) {  // missing role label
      const roles = ['Χρήστης', 'Διαχειριστής','Απλός χρήστης', 'Factor']
      cellText = roles[cell.data.UserRole];
    } else if (cell.column.dataField === 'IsAdmin' && cell.column.dataType === 'boolean') { // respect Admin checkbox in supplier invitation
      /* Create a dummy checkbox for styling purposes
      *  inside supplier invitation Admin user grid
      */
      var newCheckbox = document.createElement('div');
      newCheckbox.className = cell.value
        ? 'dx-datagrid-checkbox-size dx-show-invalid-badge dx-checkbox dx-checkbox-checked dx-state-readonly dx-widget'
        : 'dx-datagrid-checkbox-size dx-show-invalid-badge dx-checkbox dx-state-readonly dx-widget';

      var checkboxContainer = document.createElement('div');
      checkboxContainer.className = 'dx-checkbox-container';

      var checkboxIcon = document.createElement('span');
      checkboxIcon.className = 'dx-checkbox-icon';

      checkboxContainer.appendChild(checkboxIcon);
      newCheckbox.appendChild(checkboxContainer);
      cellText = newCheckbox
    }
    element.append(cellText);
  }
}
