const minLenthError = '8 έως 20 χαρακτήρες';
const hasNumberError = '1 αριθμό';
const hasCapitalCaseError = '1 κεφαλαίο';
const hasLowerCaseError = '1 πεζό';
const hasSpecialCharacters = '1 ειδικός χαρακτήρας';

export const PasswordHintErrors = {
  minLenthError,
  hasNumberError,
  hasCapitalCaseError,
  hasLowerCaseError,
  hasSpecialCharacters,
};
