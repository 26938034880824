<section
  fxLayout="column"
  class="color-blue control-container"
  [ngClass]="{
    'field-for-edit':
      (shouldApproveForEdit | async) || (forEdit && (canClickForEdit | async)),
    'cursor-pointer': (canClickForEdit | async)
  }"
  (click)="setForEdit()"
  fxFlex="grow"
>
  <div fxLayout="row" fxLayoutAlign="start center" class="label-row">
    <label [for]="to.label" class="color-blue">
      {{ to.label }}
      <span
        *ngIf="field.templateOptions.required"
        [ngStyle]="{
          color: formControl.invalid ? 'red' : 'initial'
        }"
        >*</span
      ></label
    >
    <mat-icon
      *ngIf="to?.description"
      id="pop{{ key }}"
      (mouseenter)="showInfo = !showInfo"
      (mouseleave)="showInfo = !showInfo"
      class="color-blue onHover"
      >info</mat-icon
    >
    <dx-popover
      target="#pop{{ key }}"
      position="top"
      [width]="300"
      [(visible)]="showInfo"
      *ngIf="to?.description"
    >
      <div *dxTemplate="let data = model; of: 'content'">
        {{ to?.description }}
      </div>
    </dx-popover>
  </div>

  <dx-select-box
    [dataSource]="options$ | async"
    [formControl]="formControl"
    [displayExpr]="to.labelProp"
    [valueExpr]="to.valueProp"
    [searchMode]="'startswith'"
    [searchEnabled]="true"
    fxFlex="grow"
  ></dx-select-box>
  <mat-error>
    <formly-validation-message [field]="field"></formly-validation-message>
  </mat-error>
</section>
