import { SupplierGuard } from './guards/supplier.guard';
import { BuyerGuard } from './guards/buyer.guard';
import { FactorGuard } from './guards/factor.guard';
import { ViewerGuard } from './guards/viewer.guard';
import { SuperUserGuard } from './guards/superuser.guard';
import { AdministratorGuard } from './guards/administrator.guard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthEffects } from '../lib/store/auth.effects';
import { LoginApiService } from './api/login.api.service';
import { AUTH_STORE_KEY } from './auth.variable';
import { AuthGuard } from './guards/auth.guard';
import { authReducer } from './store';
import { NotFactorGuard } from './guards';
import { IsBuyerAndSupplierGuard } from './guards/IsBuyerAndSupplier.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReportGuard } from './guards/reports.guard';


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(AUTH_STORE_KEY, authReducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  providers: [
    LoginApiService,
    AuthGuard,
    AdministratorGuard,
    SuperUserGuard,
    ViewerGuard,
    FactorGuard,
    BuyerGuard,
    SupplierGuard,
    NotFactorGuard,
    IsBuyerAndSupplierGuard,
    ReportGuard
  ],
})
export class UtilAuthModule {}
