<section
  fxLayout="column"
  class="color-blue control-container"
  [ngClass]="{
    'field-for-edit':
    (shouldApproveForEdit | async) || (forEdit && (canClickForEdit | async)),
    'cursor-pointer': (canClickForEdit | async)
  }"
  (click)="setForEdit()"
  fxFlex="grow"
>
  <label [for]="to.label">
    {{ to.label }}
    <span
      *ngIf="field.templateOptions.required"
      [ngStyle]="{
        color: formControl.invalid ? 'red' : 'initial'
      }"
      >*</span
    ></label
  >
  <mat-form-field appearance="outline" color="primary" fxFlex="grow">
    <mat-select
      [attr.name]="field.key"
      [formControl]="formControl"
      [attr.data-cy]="field.templateOptions.attributes['data-cy']"
    >
      <mat-option
        *ngFor="let option of options$ | async"
        [value]="option[to.valueProp]"
      >
        {{ option[to.labelProp] }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-error>
    <formly-validation-message [field]="field"></formly-validation-message>
  </mat-error>
</section>
