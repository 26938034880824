import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';
export function createRow(fieldConfig: FormlyFieldConfig[]): FormlyFieldConfig {
  fieldConfig.forEach((field, i) => {
    let cssClass = 'grow';
    if (i < fieldConfig.length - 1) {
      cssClass += ' mr-1';
    }
    field.className = field.className
      ? field.className + ` ${cssClass}`
      : cssClass;
  });
  return {
    fieldGroupClassName: 'row grow',
    fieldGroup: [...fieldConfig],
  };
}
export const dataCyExtension: FormlyExtension = {
  prePopulate(field: FormlyFieldConfig) {
    field.templateOptions = {
      ...(field.templateOptions || {}),
      attributes: {
        'data-cy': field.key as string,
      },
    };
  },
};
export function RequiredValidationMessage(err, field: FormlyFieldConfig) {
  if (field.formControl.dirty) {
    return `Το πεδίο είναι υποχρεωτικό.`;
  }
}
