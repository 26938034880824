import { createAction, props } from '@ngrx/store';

export const ToggleMenu = createAction('ToggleMenu');
export const SetLoadingScreen = createAction(
  'SetLoadingScreen',
  props<Partial<{ message: string }>>()
);
export const CloseLoadingScreen = createAction('CloseLoadingScreen');
export const SetPageTitle = createAction(
  'SetPagetitle',
  props<{ title: string }>()
);
export const reset_loading_message = createAction('RESET_LOADING_MESSAGE');
