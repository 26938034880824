import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { v4 as uuid } from 'uuid';
import {
  add_update_field,
  remove_update_field,
} from './../../../store/forms.actions';
import {
  selectApproveUpdateField,
  selectToggleForModify,
} from './../../../store/forms.selectors';
import { first, map, takeUntil, tap } from 'rxjs/operators';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'erbfactors-formly-ng-select-autocomplete',
  templateUrl: './formly-ng-select-autocomplete.component.html',
  styleUrls: ['./formly-ng-select-autocomplete.component.scss'],
})
export class FormlyNgSelectAutocompleteComponent
  extends FieldType
  implements OnInit, OnDestroy {
  canClickForEdit: Observable<boolean>;
  shouldApproveForEdit: Observable<boolean>;

  guid: string;
  forEdit = false;
  formControl: FormControl;
  options$: Observable<unknown[]>;
  unsubscribe$ = new Subject();

  showInfo: boolean = false;

  constructor(private store: Store) {
    super();
  }

  ngOnInit() {
    this.options$ = this.to.options as Observable<unknown[]>;
    this.canClickForEdit = this.store.select(selectToggleForModify);
    this.shouldApproveForEdit = this.store
      .select(selectApproveUpdateField, {
        formName: this.options.formState.name,
        name: this.field.key as string,
      })
      .pipe(
        takeUntil(this.unsubscribe$),
        map((field) => !!field)
      );
  }

  setForEdit() {
    this.canClickForEdit
      .pipe(
        takeUntil(this.unsubscribe$),
        first(),
        tap((bool) => {
          if (bool) {
            this.forEdit = !this.forEdit;
            if (!this.guid) this.guid = uuid();
            if (this.forEdit) {
              this.store.dispatch(
                add_update_field({
                  field: {
                    guid: this.guid,
                    name: <string>this.field.key,
                    formName: this.options.formState.name,
                  },
                })
              );
            } else {
              this.store.dispatch(remove_update_field({ guid: this.guid }));
            }
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
