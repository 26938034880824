<section
  fxLayout
  fxLayoutAlign="space-between center"
  fxFlex="grow"
  [ngStyle]="_styles"
>
  <section fxLayout fxLayoutGap="1rem" fxLayoutAlign="start center"
  >
    <img class="erbf-logo" alt="" />
    <span class="footer-text">
      <!-- Copyright © {{ year }} | <a href="#"> Όροι και προϋποθέσεις </a> -->
      </span
    >
  </section>
  <section fxLayout fxLayoutGap="1rem" fxLayoutAlign="start center">
    <div class="link-container">
      <div class="link-head">
        ΠΛΗΡΟΦΟΡΙΕΣ
      </div>
      <hr class="cus-hr">
      <div class="link">
        Μάθε περισσότερα για τη <a href="http://www.eurobankfactors.gr/" target="_blank">Eurobank Factors</a>
      </div>
    </div>
  </section>
  <section fxLayout fxLayoutGap="1rem" fxLayoutAlign="start center">
    <div class="link-container">
      <div class="link-head">
        ΒΟΗΘΕΙΑ
      </div>
      <hr class="cus-hr">
      <div class="link"><a href="mailto:esupport@eurobankfactors.gr" target="_blank">Επικοινωνία</a>
      </div>
    </div>
  </section>
  <section fxLayout fxLayoutGap="1rem" fxLayoutAlign="start center">
    <div class="link-container">
      <div class="link-head">
        ΠΡΕΠΕΙ ΝΑ ΓΝΩΡΙΖΕΤΕ
      </div>
      <hr class="cus-hr">
      <div class="link"><a href="https://www.eurobankfactors.gr/App_Themes/pdf/gdpr_gr.pdf" target="_blank">GDPR – Προσωπικά Δεδομένα</a>
      </div>
    </div>
  </section>
  <section fxLayout fxLayoutGap="1rem" fxLayoutAlign="start center">
    <span class="footer-text">Powered By </span>
    <img class="impact-logo" alt="" />
  </section>
</section>
