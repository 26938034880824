<div
  class="alert text-center cookiealert"
  [ngClass]="{ show: show }"
  role="alert"
>
  Αυτή η ιστοσελίδα χρησιμοποιεί cookies προκειμένου να επιτρέπονται βασικές
  λειτουργίες όπως η πλοήγηση και η πρόσβαση σε ασφαλείς περιοχές της
  ιστοσελίδας.
  <button
    type="button"
    (click)="onAgree()"
    class="btn btn-primary btn-sm acceptcookies"
  >
    Το κατάλαβα
  </button>
</div>
