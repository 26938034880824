import { AbstractControl } from '@angular/forms';

export const isControlRequired = (control: AbstractControl): boolean => {
  if (!control) {
    return false;
  }

  if (control.validator) {
    const validator = control.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  return false;
};
