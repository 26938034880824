import { Action, createReducer, on } from '@ngrx/store';
import * as CompanySharedActions from './company-shared.actions';
import {
  CompanySharedInitialState,
  CompanySharedState,
} from './company-shared.state';
const _company_shared_reducer = createReducer(
  CompanySharedInitialState,
  on(CompanySharedActions.set_vrn_and_guid, (state, action) => ({
    ...state,
    guid: action.guid,
    vrn: action.vrn,

  })),
  on(CompanySharedActions.set_buyer_Name, (state, action) => ({  //TARAS
    ...state,
     buyerName: action.buyerName
  })),
  on(CompanySharedActions.set_legal_form, (state, action) => ({
    ...state,
    legalForm: action.legalForm,
  })),
  on(CompanySharedActions.set_legal_forms, (state, action) => ({
    ...state,
    legalForms: action.legalForms,
  })),
  on(CompanySharedActions.patch_form_metadata, (state, action) => {
    return { ...state, formMetadata: action.metadata };
  }),
  on(CompanySharedActions.set_status, (state, action) => ({
    ...state,
    status: action.status,
    statusDescription: action.statusDescription,
  })),
  on(CompanySharedActions.reset_company_shared_store, (state) => ({
    ...state,
    ...CompanySharedInitialState,
  })),
  on(CompanySharedActions.reset_status, (status) => ({
    ...status,
    status: null,
    statusDescription: null,
  }))
);
export function company_shared_reducer(
  state: CompanySharedState | undefined,
  action: Action
) {
  return _company_shared_reducer(state, action);
}
