import { Component, OnInit } from '@angular/core';
const FN_COOKIE_NAME = '_fn';

@Component({
  selector: 'erbfactors-cookies-alert',
  templateUrl: './cookies-alert.component.html',
  styleUrls: ['./cookies-alert.component.scss'],
})
export class CookiesAlertComponent implements OnInit {
  show: boolean = false;
  ngOnInit() {
    let isCookiesSet = this.getCookie(FN_COOKIE_NAME);
    if (!isCookiesSet) {
      this.showAlert();
    }
  }

  showAlert() {
    setTimeout(() => {
      this.show = true;
    }, 5000);
  }

  hideAlert() {
    this.show = false;
  }

  onAgree() {
    this.setCookies();
    this.hideAlert();
  }

  setCookies() {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 15778476000;
    now.setTime(expireTime);
    document.cookie = '_fn=true;expires=' + now.toUTCString() + ';path=/';
  }

  getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
}
