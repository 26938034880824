import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SnackBarService } from '@erbfactors/shared-ui';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { is_factor, is_buyer, is_supplier, is_viewer, is_administrator } from './../store/auth.selectors';

@Injectable()
export class ReportGuard implements CanActivate {
 isSupplier: boolean;
 isAdministrator: boolean;
 isViewer: boolean;
  
    constructor(
    private store: Store,
    private router: Router,
    private snackbar: SnackBarService
  ) {}
  canActivate() {
    this.store.select(is_supplier).subscribe(
        supplier => {
            this.isSupplier = supplier;
            this.store.select(is_viewer).subscribe(
                viewer => {
                   this.isViewer = viewer
                    this.store.select(is_administrator).subscribe(
                        administrator => {
                          this.isAdministrator = administrator;

                          if(this.isSupplier ||this.isAdministrator ||this.isViewer )
                          return true
                        }
                    )

                }
            )
        }       
    );
    return false;
  }
}