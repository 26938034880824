import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PasswordHintErrors } from './password-hint.variable';

@Component({
  selector: 'erbfactors-password-hint',
  templateUrl: './password-hint.component.html',
  styleUrls: ['./password-hint.component.scss'],
})
export class PasswordHintComponent implements OnInit {
  passwordHintErrors = PasswordHintErrors;
  @Input('control') control: AbstractControl;
  ngOnInit() {}
}
