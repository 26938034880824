import { FormArray, ValidatorFn } from '@angular/forms';

export function DuplicateShareholderVrnValidator(
  controlName: string
): ValidatorFn {
  return (formArray: FormArray): { [key: string]: boolean } | null => {
    const error = { duplicate_vrn: true };
    let bool = false;
    if (!formArray.controls.length) return null;
    const vrns = formArray.controls.map((form) => form.get(controlName).value);
    bool = new Set(vrns).size !== vrns.length;
    return bool ? error : null;
  };
}
