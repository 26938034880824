import { ValidatorFn, AbstractControl } from '@angular/forms';
export function GreekVrnValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    return vrnValidator(control.value);
  };
}
export function vrnValidator(afm) {
  if (!afm) return null;
  if (!afm.match(/^\d{9}$/) || afm == '000000000') return { vrn: true };

  let m = 1,
    sum = 0;
  for (let i = 7; i >= 0; i--) {
    m *= 2;
    sum += afm.charAt(i) * m;
  }
  const result = (sum % 11) % 10 == afm.charAt(8);
  return result ? null : { vrn: true };
}
