export interface CompanyLocation {
  IsActive: number;
  LocId: number;
  LocNameEl: string;
  LocNameEn: string;
  PostalCode: string;
  PrefPrefId: number;
}
export interface CompanyLocationResponse {
  '@odata.context': string;
  value: CompanyLocation[];
}
export class CompanyLocationViewModel {
  LocId: number;
  LocLabel: string;
  PrefPrefId: number;
  PostalCode: string;
  constructor(location: CompanyLocation) {
    this.LocId = location.LocId;
    this.LocLabel = location.LocNameEl + ' - ' + location.PostalCode;
    this.PrefPrefId = location.PrefPrefId;
    this.PostalCode = location.PostalCode;
  }
}
