import { environment } from '@erbfactors/environment';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { locale } from 'devextreme/localization';
import { registerLocaleData } from '@angular/common';

import localeEl from '@angular/common/locales/el';
registerLocaleData(localeEl, 'el');

if (environment.name === 'production') {
  enableProdMode();
}
locale('el-GR');
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
