import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';
export const getAuthState = createFeatureSelector<AuthState>('auth');
export const select_token = createSelector(
  getAuthState,
  (state: AuthState) => state.token
);
export const need_to_reset = createSelector(
  getAuthState,
  (state: AuthState) => state.mustResetPassword
);
export const selected_company = createSelector(
  getAuthState,
  (state) => state.companyId
);
export const available_companies = createSelector(
  getAuthState,
  (state) => state.available_companies
);
export const get_roles = createSelector(getAuthState, (state) => state.roles);
export const get_claims = createSelector(getAuthState, (state) => state.claims);
export const is_administrator = createSelector(get_roles, (roles) =>
  roles.includes('Administrator')
);
export const is_superuser = createSelector(get_roles, (roles) =>
  roles.includes('SuperUser')
);
export const is_viewer = createSelector(get_roles, (roles) =>
  roles.includes('Viewer')
);
export const is_factor = createSelector(get_roles, (roles) =>
  roles.includes('Factor')
);
export const is_buyer = createSelector(get_claims, (claims) => claims.is_buyer);

export const is_supplier = createSelector(
  get_claims,
  (claims) => claims.is_supplier
);
export const can_edit = createSelector(
  get_roles,
  (roles) => roles.includes('Administrator') || roles.includes('SuperUser')
);

// export const is_report_user = createSelector(
//   get_roles,
//   (roles) => roles.includes('Administrator') || roles.includes('Viewer') || roles.includes('Supplier')
// );

