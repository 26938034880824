import { UpdateField } from '@erbfactors/util-forms';
import { MetadataObject } from '../model';
import { v4 as uuid } from 'uuid';
export function UpdateFieldsToMetadata(
  fields: UpdateField[]
): MetadataObject[] {
  const metadata: MetadataObject[] = [];
  for (const field of fields) {
    const existingItem = metadata.find((m) => m.Object === field.formName);
    if (existingItem) {
      if (field.name) {
        existingItem.Fields.push({
          Column: field.name,
          Editable: true,
        });
      } else {
        throw new Error(
          '1122 - There should be no existing Item paired with a field with No name'
        );
      }
    } else {
      const newItem: MetadataObject = {
        Object: field.formName,
        Editable: !field.name,
        Fields: [],
      };
      if (field.name) {
        newItem.Fields.push({ Column: field.name, Editable: true });
      }
      metadata.push(newItem);
    }
  }
  return metadata;
}
export function MetadataToUpdateFields(
  metadata: MetadataObject[]
): UpdateField[] {
  const fields: UpdateField[] = [];
  for (const data of metadata) {
    if (data.Editable && !data.Fields.length) {
      fields.push({ formName: data.Object, guid: uuid(), name: null });
    }
    if (!data.Editable && data.Fields.length) {
      for (const field of data.Fields) {
        fields.push({
          formName: data.Object,
          name: field.Column,
          guid: uuid(),
        });
      }
    }
  }
  return fields;
}
