import { ValidatorFn, AbstractControl } from '@angular/forms';
export function FaxValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const value: string = control.value;
    if (!value) return null;
    const error = { fax: true };
    const regex = new RegExp(/^(?=\d{10}$)(2)\d+/);

    return regex.test(value) ? null : error;
  };
}
