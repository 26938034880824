import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { GreekVrnValidator } from '@erbfactors/util-forms';
export interface ProceduralRepresentative {
  FatherName: string;
  VatNumber: string;
  IdNumber: string;
  Address: string;
  Fullname: string;
  CprId: number;
}

export type ProceduralRepresentativeControlType =
  | 'Fullname'
  | 'FatherName'
  | 'VatNumber'
  | 'IdNumber'
  | 'Address'
  | 'CprId';
export const ProceduralRepresentativeControls: () => Record<
  ProceduralRepresentativeControlType,
  AbstractControl
> = () => ({
  Fullname: new FormControl(null, Validators.required),
  FatherName: new FormControl(null, Validators.required),
  VatNumber: new FormControl(null, [GreekVrnValidator()]),
  IdNumber: new FormControl(),
  Address: new FormControl(null, Validators.required),
  CprId: new FormControl(),
});
export class ProceduralRepresentativeFormGroup extends FormGroup {
  constructor(
    controls: Record<ProceduralRepresentativeControlType, AbstractControl>,
    validators: ValidatorFn[] = []
  ) {
    super(controls, validators);
  }
}
