import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select_token } from '@erbfactors/util-auth';
import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return this.store.select(select_token).pipe(
      take(1),
      switchMap((token) => {
        if (hasBearer(request)) {
          const newRequest = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`),
          });
          return next.handle(newRequest);
        } else {
          return next.handle(request);
        }
      })
    );
  }
}

function hasBearer(request: HttpRequest<unknown>): boolean {
  const unauthorized_urls = [
    'login/login',
    'locations',
    'prefectures',
    'legalforms',
    'login/forcelogin'  // Actually is an authorized URL but should be ignored from this interceptor
  ];
  let bool = true;
  let i = 0;
  while (bool && i < unauthorized_urls.length) {
    bool = !request.url.toLowerCase().includes(unauthorized_urls[i]);
    i++;
  }
  return bool;
}
