import { FormGroup } from '@angular/forms';
import {
  ATTACHMENTS_FORM_NAME,
  COMPANY_BIND_SIGNRATURES_FORM_NAME,
  COMPANY_INFO_FORM_NAME,
  PROCEDURAL_REPRESENTATIVE_FORM_NAME,
  SHAREHOLDERS_FORM_NAME,
  USERS_FORM_NAME,
  USER_GROUPS_FORM_NAME,
} from './company-form.variable';
import {
  AttachmentsFormArray,
  CompaniesBindFormArray,
  CompaniesBindSignature,
  CompanyInfo,
  CompanyInfoControls,
  CompanyInfoFormGroup,
  ProceduralRepresentativeFormGroup,
  Shareholder,
  ShareholdersFormArray,
  UserGroupsFormArray,
  UsersFormArray,
} from './model';
import { FileDto } from './model/attachment.model';
import {
  ProceduralRepresentative,
  ProceduralRepresentativeControls,
} from './model/procedural-representative.model';
import { User, UserGroup } from './model/users-form.model';

export type FormNames =
  | typeof COMPANY_INFO_FORM_NAME
  | typeof SHAREHOLDERS_FORM_NAME
  | typeof COMPANY_BIND_SIGNRATURES_FORM_NAME
  | typeof PROCEDURAL_REPRESENTATIVE_FORM_NAME
  | typeof USER_GROUPS_FORM_NAME
  | typeof USERS_FORM_NAME
  | typeof ATTACHMENTS_FORM_NAME
  | 'noform';

export class CompanyDetails {
  [COMPANY_INFO_FORM_NAME]: CompanyInfo;
  [SHAREHOLDERS_FORM_NAME]: Shareholder[];
  [COMPANY_BIND_SIGNRATURES_FORM_NAME]: CompaniesBindSignature[];
  [PROCEDURAL_REPRESENTATIVE_FORM_NAME]: ProceduralRepresentative;
  [USER_GROUPS_FORM_NAME]: UserGroup[];
  [USERS_FORM_NAME]: User[];
  [ATTACHMENTS_FORM_NAME]: FileDto[];
  constructor() {
    this[COMPANY_INFO_FORM_NAME] = null;
    this[SHAREHOLDERS_FORM_NAME] = null;
    this[COMPANY_BIND_SIGNRATURES_FORM_NAME] = null;
    this[PROCEDURAL_REPRESENTATIVE_FORM_NAME] = null;
    this[USER_GROUPS_FORM_NAME] = null;
    this[USERS_FORM_NAME] = null;
    this[ATTACHMENTS_FORM_NAME] = null;
  }
}
export class CompanyForm extends FormGroup {
  constructor() {
    super({
      [COMPANY_INFO_FORM_NAME]: new CompanyInfoFormGroup({
        ...CompanyInfoControls(),
      }),
      [SHAREHOLDERS_FORM_NAME]: new ShareholdersFormArray(),
      [COMPANY_BIND_SIGNRATURES_FORM_NAME]: new CompaniesBindFormArray(),
      [PROCEDURAL_REPRESENTATIVE_FORM_NAME]: new ProceduralRepresentativeFormGroup(
        { ...ProceduralRepresentativeControls() }
      ),
      [USER_GROUPS_FORM_NAME]: new UserGroupsFormArray(),
      [USERS_FORM_NAME]: new UsersFormArray([]),
      [ATTACHMENTS_FORM_NAME]: new AttachmentsFormArray(),
    });
  }

  get companyInfoForm(): CompanyInfoFormGroup {
    //let test = this.get(COMPANY_INFO_FORM_NAME) as CompanyInfoFormGroup;
    return this.get(COMPANY_INFO_FORM_NAME) as CompanyInfoFormGroup;
  }
  get shareholdersForm() {
    return this.get(SHAREHOLDERS_FORM_NAME) as ShareholdersFormArray;
  }
  get proceduralRepresentativeForm() {
    return this.get(PROCEDURAL_REPRESENTATIVE_FORM_NAME) as FormGroup;
  }
  get companyBindSignaturesForm() {
    return this.get(
      COMPANY_BIND_SIGNRATURES_FORM_NAME
    ) as CompaniesBindFormArray;
  }
  get userGroupsForm(): UserGroupsFormArray {
    return this.get(USER_GROUPS_FORM_NAME) as UserGroupsFormArray;
  }
  get usersForm(): UsersFormArray {
    return this.get(USERS_FORM_NAME) as UsersFormArray;
  }
  get attachmentsForm(): AttachmentsFormArray {
    return this.get(ATTACHMENTS_FORM_NAME) as AttachmentsFormArray;
  }
  patch(data: CompanyDetails) {
    this.companyInfoForm.patchValue({ ...data[COMPANY_INFO_FORM_NAME] });
    this.shareholdersForm.patch(data[SHAREHOLDERS_FORM_NAME]);
    this.companyBindSignaturesForm.patch(
      data[COMPANY_BIND_SIGNRATURES_FORM_NAME]
    );
    this.proceduralRepresentativeForm.patchValue(
      data[PROCEDURAL_REPRESENTATIVE_FORM_NAME]
    );
    this.userGroupsForm.patch(data[USER_GROUPS_FORM_NAME]);
    this.usersForm.patch(data[USERS_FORM_NAME]);
    this.attachmentsForm.patch(data[ATTACHMENTS_FORM_NAME]);
  }
  clear() {
    this.companyInfoForm.reset();
    this.shareholdersForm.clear();
    this.companyBindSignaturesForm.clear();
    this.proceduralRepresentativeForm.reset();
    this.userGroupsForm.clear();
    this.usersForm.clear();
    this.attachmentsForm.clear();
  }
}
