import { AuthState } from './auth.state';
import { LoginResponse } from './../api/api.model';
import { createAction, props } from '@ngrx/store';
import { LoginDto, ResetPassDto } from '../dto';
import { Role } from '../model';
import { ChangePasswordDto } from '../dto/change-password.dto';
import { UserActivateDTO } from '../dto/user-activate.dto';

export const EMPTY = createAction('EMPTY');
export const login = createAction('[LOGIN] LOGIN', props<LoginDto>());
export const refresh = createAction('[LOGIN] REFRESH TOKEN');
export const forgot = createAction(
  '[FORGOT] FORGOT',
  props<{ Email: string }>()
);
export const reset = createAction('[RESET] RESET', props<ResetPassDto>());
export const getOTP = createAction(
  '[OTP] USER ACTIVATION',
  props<{ Email: string }>()
);
export const activateUser = createAction(
  '[ACTIVATE USER POST] ACTIVATE USER',
  props<UserActivateDTO>()
);
export const changePassword = createAction(
  '[CHANGE_PASSWORD] CHANGE_PASSWORD',
  props<ChangePasswordDto>()
);
export const after_login = createAction(
  '[LOGIN] AFTER_LOGIN',
  props<LoginResponse>()
);
export const from_cache = createAction(
  '[LOGIN] FROM_CACHE',
  props<{ payload: AuthState }>()
);
export const logout = createAction('LOGOUT');
export const logoutSuccess = createAction('LOGOUT SUCCESS');
export const get_token_with_company_id = createAction(
  'GET_TOKEN_WITH_COMPANY_ID',
  props<{ companyId: number }>()
);
export const set_selected_company = createAction(
  'SET_SELECTED_COMPANY',
  props<{ companyId: number }>()
);
export const set_available_companies = createAction(
  'SET_AVAILABLE_COMPANIES',
  props<{ companies: { name: string; id: number | string }[] }>()
);
export const set_roles = createAction('SET_ROLES', props<{ roles: Role[] }>());
export const set_claims = createAction(
  'SET_CLAIMS',
  props<{ is_buyer: boolean; is_supplier: boolean }>()
);
export const download_manual_zip = createAction(
  'DOWNLOAD_MANUALS_ZIP');
