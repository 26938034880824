import { AbstractControl, ValidatorFn } from '@angular/forms';
// export function MobileValidator(): ValidatorFn {
//   return (control: AbstractControl): { [key: string]: boolean } | null => {
//     if (!control.value) return null;
//     const error = { mobile: true };
//     const regex = new RegExp(/^(?=\d{10}$)(69)\d+/);
//     const value: string = control.value;
//     return regex.test(value) ? null : error;
//   };
// }

//ONLY NUMBERS
export function MobileValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value) return null;
    const error = { mobile: true };
    const regex = new RegExp(/^[0-9]*$/);
    const value: string = control.value;
    return regex.test(value) ? null : error;
  };
}
