<section fxLayout="column">
  <section
    fxLayout
    fxFlex="grow"
    [fxLayoutAlign]="inline_form_align"
    *ngIf="gridForm.enabled"
  >
    <form
      [formGroup]="inlineForm"
      fxLayout.lt-lg="row wrap"
      fxLayout="row wrap"
      data-cy="GridInputForm"
    >
      <ng-container *ngFor="let field of visibleFields" [ngSwitch]="field.type">
        <ng-container *ngIf="field.name==='CbsAddress'; else simpleText">
          <div class="break-line"></div>
        <erbfactors-text-control
            class="full-width"
          *ngSwitchCase="'text'"
          [control]="inlineForm.get(field.name)"
          [field]="field"
          [form]="inlineForm"
        ></erbfactors-text-control>
        </ng-container>

        <ng-template #simpleText>
          <erbfactors-text-control
            *ngSwitchCase="'text'"
            [control]="inlineForm.get(field.name)"
            [field]="field"
            [form]="inlineForm"
          ></erbfactors-text-control>
        </ng-template>
        <erbfactors-number-control
          *ngSwitchCase="'number'"
          [control]="inlineForm.get(field.name)"
          [field]="field"
          [form]="inlineForm"
        ></erbfactors-number-control>
        <erbfactors-checkbox-control
          *ngSwitchCase="'checkbox'"
          [control]="inlineForm.get(field.name)"
          [field]="field"
          [form]="inlineForm"
        ></erbfactors-checkbox-control>
        <erbfactors-select-control
          *ngSwitchCase="'select'"
          [control]="inlineForm.get(field.name)"
          [field]="field"
          [form]="inlineForm"
        ></erbfactors-select-control>
      </ng-container>
    </form>
    <button
      class="add-btn mr-2"
      [attr.data-cy]="'add-form'"
      mat-mini-fab
      color="primary"
      mat-raised-button
      [disabled]="inlineForm.invalid"
      (click)="addToForm()"
    >
      <mat-icon>add_outline</mat-icon>
    </button>
  </section>

  <section>
    <dx-data-grid
      class="dx-grid-array"
      [ngClass]="{ 'center-grid': center_grid }"
      [dataSource]="gridValues | async"
      [showBorders]="true"

      (onRowUpdated)="updated($event)"
      (onRowRemoving)="removed($event)"
      (onEditorPreparing)="resizePopupWidth($event)"
      (onRowUpdating)="onRowUpdating($event)"
    >
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-editing
        mode="row"
        [allowUpdating]="gridForm.enabled && allowEditAdmin"
        [allowDeleting]="gridForm.enabled && allowEditAdmin"
        [confirmDelete]="true"
        [useIcons]="true"
      >
      <dxo-texts confirmDeleteMessage="Επιθυμείτε τη διαγραφή της εγγραφής;"></dxo-texts> 
      </dxo-editing>
      <dxi-column
        fxFlex="1"
        *ngFor="let field of visibleFields"
        [dataField]="field.name"
        [caption]="field.displayName"
        [minWidth]="field.styles?.minWidth || 'auto'"
        [width]="field.styles?.width || 'auto'"
        [allowEditing]="!(field.name === 'CuEmail')"
        [cellTemplate]="displayRoles"
      >
        <dxi-validation-rule
          *ngFor="let validation of field?.validations"
          [type]="validation.type"
          [message]="validation.message"
          [validationCallback]="validation.callback"
        ></dxi-validation-rule>
        <dxo-lookup
          *ngIf="field.type === 'select'"
          [dataSource]="field.options | async"
          [valueExpr]="field.valueField"
          [displayExpr]="field.displayField"
        >
        </dxo-lookup>
      </dxi-column>
      <dxo-load-panel *ngIf="!!loading" [enabled]="loading | async">
      </dxo-load-panel>
    </dx-data-grid>
  </section>
</section>
