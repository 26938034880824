import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { routeAnimations, SnackBarService } from '@erbfactors/shared-ui';
import { Store } from '@ngrx/store';
import config from 'devextreme/core/config';

@Component({
  selector: 'erbfactors-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations],
})
export class AppComponent implements OnInit {
  constructor(private store: Store, private snackbar: SnackBarService) {}
  ngOnInit() {

    config({
      defaultCurrency: 'EUR',
    });
    this.store.subscribe((s) => {
      console.log(s);
    });
  }
  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }
}
