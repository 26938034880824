import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'erbfactors-otp-dialog',
  templateUrl: './otp-dialog.component.html',
  styleUrls: ['./otp-dialog.component.scss'],
})
export class OtpDialogComponent implements OnInit, AfterViewInit {
  @Output() dialog_close: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('optInput', { static: false }) optInput: ElementRef;
  form: FormGroup;
  constructor(public dialogRef: MatDialogRef<OtpDialogComponent>) {}

  ngOnInit() {
    this.form = new FormGroup({
      otp: new FormControl(null, Validators.required),
    });
  }
  close(bool: boolean) {
    if (bool) {
      this.dialog_close.emit(this.form.get('otp').value);
    } else {
      this.dialog_close.emit(null);
    }
  }

  ngAfterViewInit() {
    fromEvent(this.optInput.nativeElement, 'keydown')
      .pipe(filter((event: KeyboardEvent) => event.keyCode === 13))
      .subscribe(() => {
        this.close(true);
      });
  }
}
