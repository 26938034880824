import { ValidatorFn, AbstractControl } from '@angular/forms';
export function EmailCustomValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value) return null;
    const error = { email: true };
    const regex = new RegExp(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/);
    const value: string = control.value;
    return regex.test(value) ? null : error;
  };
}
