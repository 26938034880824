<section
  class="color-blue control-container"
  [ngStyle]="styles"
  [formGroup]="form"
>
  <label [for]="field.name">
    {{ field.displayName }}
    <span
      *ngIf="required"
      [ngStyle]="{
        color:
          control.hasError('required') && control.touched ? 'red' : 'initial'
      }"
      >*</span
    >
  </label>
  <mat-form-field appearance="outline" color="primary" fxFlex="grow">
    <input
      matInput
      [min]="field.min"
      [max]="field.max"
      [maxLength]="field.maxLength"
      type="number"
      [attr.data-cy]="field.name"
      [name]="field.name"
      [formControlName]="field.name"
    />
  </mat-form-field>
  <section *ngFor="let error of errors">
    <mat-error
      [@fadeInOut]
      *ngIf="showErrors && control.hasError(error.name) && control.touched"
      >{{ error.message }}</mat-error
    >
  </section>
</section>
