import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { USER_STORAGE_KEY } from '../auth.variable';
import { AuthState, from_cache } from '../store';
import { select_token } from '../store/auth.selectors';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  canActivate() {
    return this.store.select(select_token).pipe(
      map((token) => {
        if (!token) {
          const jsonData = localStorage.getItem(USER_STORAGE_KEY);
          if (jsonData) {
            const parsedData: AuthState = JSON.parse(jsonData);
            this.store.dispatch(from_cache({ payload: parsedData }));
            // this.store.dispatch(CloseLoadingScreen());
            if (new Date(parsedData.expirationDate) > new Date()) {
              return true;
            }
          } else {
            let redirectTo = window.location.pathname;
            if (redirectTo && redirectTo !== '/')
              this.router.navigateByUrl(
                `/login?redirectTo=${window.location.pathname}`
              );
            else this.router.navigate([`/login`]);
            // this.store.dispatch(CloseLoadingScreen());
            return false;
          }
        } else {
          // this.store.dispatch(CloseLoadingScreen());
          return true;
        }
      })
    );
  }
}
