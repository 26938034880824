export const COMPANY_INFO_FORM_NAME = 'CompanyInfo';
export const USER_GROUPS_FORM_NAME = 'UserGroups';
export const USERS_FORM_NAME = 'Users';
export const ATTACHMENTS_FORM_NAME = 'FilesDtos';

export const PROCEDURAL_REPRESENTATIVE_FORM_NAME = 'ProceduralRepresentative';
export const COMPANY_BIND_SIGNRATURES_FORM_NAME = 'CompaniesBindSignatures';
export const SHAREHOLDERS_FORM_NAME = 'Shareholders';
export const INVITATIONS_INSPECTION_FORM_NAME = 'InvitationsInspection';
export const VRN_NUMBER_CONTROL_NAME = 'VatNumber';

export const LEGAL_FORM_CONTROL_NAME = 'LegalForm';
export const ZIP_CONTROL_NAME = 'Zip';
export const PREFECTURE_ID_CONTROL_NAME = 'PrefectureId';
export const LOCATION_ID_CONTROL_NAME = 'LocationId';
export const COMPANY_SHARED_STORE_KEY = 'company-shared';
export const SELECTED_COMPANY_LOCALSTORAGE_KEY = 'selected_company';
