import { ValidatorFn, AbstractControl } from '@angular/forms';
export function PhoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value) return null;
    const error = { phone: true };
    const regex = new RegExp(/^(?=\d{10}$)(2)\d+/);
    const value: string = control.value;
    return regex.test(value) ? null : error;
  };
}
