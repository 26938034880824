import { Router } from '@angular/router';
import {
  CloseLoadingScreen,
  SetLoadingScreen,
  SnackBarService,
} from '@erbfactors/shared-ui';
import { LoginApiService } from './../api/login.api.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, throwError } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
@Injectable()
export class AuthEffects {
  constructor(
    private domain: LoginApiService,
    private actions$: Actions,
    private store: Store,
    private snackbar: SnackBarService,
    private router: Router
  ) {}
  // login$: Observable<Action> = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.login),
  //     tap(() => this.store.dispatch(SetLoadingScreen({}))),
  //     switchMap((action) =>
  //       this.domain.login({
  //         Username: action.Username,
  //         Password: action.Password,
  //       })
  //     ),
  //     map((response) => {
  //       this.store.dispatch(CloseLoadingScreen());
  //       return AuthActions.after_login(response);
  //     }),
  //     catchError((error) => {
  //       this.store.dispatch(CloseLoadingScreen());
  //       this.store.dispatch(AuthActions.logout());
  //       return throwError(error);
  //     })
  //   )
  // );
login$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      // tap(() => this.store.dispatch(SetLoadingScreen({}))),
      switchMap((action) =>
        this.domain.login({
          Username: action.Username,
          Password: action.Password,
        })
      ),
      map((response) => {
        this.store.dispatch(CloseLoadingScreen());
        return AuthActions.after_login(response);
      }),
      catchError((error) => {
        this.store.dispatch(CloseLoadingScreen());
        // this.store.dispatch(AuthActions.logout());
        return throwError(error);
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap((action) =>
        this.domain.logout()
      ),
      map((res) => {
        return AuthActions.logoutSuccess()
      }),
      catchError((e) => {
        this.snackbar.openSnackbar({
          message: `${e?.error || 'Προέκυψε σφάλμα.'}`,
          cssClass: ['snackbar-error'],
        });
        this.store.dispatch(AuthActions.EMPTY());
        return throwError(e);
      }),
    ),
  );

  forgot$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.forgot),
      tap(() => this.store.dispatch(SetLoadingScreen({}))),
      switchMap((action) =>
        this.domain.forgot({
          Email: action.Email,
        })
      ),
      tap((response) => {
        this.store.dispatch(CloseLoadingScreen());
        this.snackbar.openSnackbar({
          message:
            'Έχει σταλεί e-mail ανάκτησης κωδικού, στην διεύθυνση που έχετε εισάγει.',
        });
        this.router.navigate([`/login`]);
        return AuthActions.EMPTY();
      }),
      map(() => AuthActions.EMPTY()),
      catchError((e) => {
        this.store.dispatch(CloseLoadingScreen());
        this.snackbar.openSnackbar({
          message: `${e?.error?.Messages[0] || 'Προέκυψε σφάλμα.'}`,
          cssClass: ['snackbar-error'],
        });
        return throwError(e);
      })
    )
  );

  getOTP$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getOTP),
      tap(() => this.store.dispatch(SetLoadingScreen({}))),
      switchMap((action) =>
        this.domain.getOTP({
          Email: action.Email,
        })
      ),
      tap((_) => {
        this.store.dispatch(CloseLoadingScreen());
        this.snackbar.openSnackbar({
          message:
            'Το OTP έχει σταλεί στο κινητό που έχει καταχωρηθεί στον λογαριασμό του εγγεγραμμένου email σας.',
        });
        return AuthActions.EMPTY();
      }),
      map(() => AuthActions.EMPTY()),
      catchError((e) => {
        this.store.dispatch(CloseLoadingScreen());
        this.snackbar.openSnackbar({
          message: `${e?.error || 'Προέκυψε σφάλμα.'}`,
          cssClass: ['snackbar-error'],
        });
        return throwError(e);
      })
    )
  );

  changePassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changePassword),
      tap(() => this.store.dispatch(SetLoadingScreen({}))),
      switchMap((action) =>
        this.domain.changePassword({
          Password: action.Password,
          NewPassword: action.NewPassword,
          ConfirmNewPassword: action.ConfirmNewPassword,
        })
      ),
      tap(() => {
        this.store.dispatch(CloseLoadingScreen());
        this.snackbar.openSnackbar({
          message: `Ο κωδικός πρόσβασης ενημερώθηκε με επιτυχία.`,
        });
        this.router.navigate(['/dashboard']);
        return AuthActions.EMPTY();
      }),
      map(() => AuthActions.EMPTY()),
      catchError((e) => {
        this.snackbar.openSnackbar({
          message: `${e?.error?.messages || 'Προέκυψε σφάλμα.'}`,
          cssClass: ['snackbar-error'],
        });
        return throwError(e);
      })
    )
  );

  reset$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.reset),
      tap(() => this.store.dispatch(SetLoadingScreen({}))),
      switchMap((action) =>
        this.domain.reset({
          Email: action.Email,
          Password: action.Password,
          ConfirmPassword: action.ConfirmPassword,
          Token: action.Token,
        })
      ),
      tap((response) => {
        this.store.dispatch(CloseLoadingScreen());
        this.snackbar.openSnackbar({ message: response.Message });
        this.router.navigate([`/login`]);
        return AuthActions.EMPTY();
      }),
      map(() => AuthActions.EMPTY()),
      catchError((e) => {
        this.store.dispatch(CloseLoadingScreen());
        this.snackbar.openSnackbar({
          message: `${e?.error?.messages || 'Προέκυψε σφάλμα.'}`,
          cssClass: ['snackbar-error'],
        });
        return throwError(e);
      })
    )
  );

  activateUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.activateUser),
      tap(() => this.store.dispatch(SetLoadingScreen({}))),
      switchMap((action) => this.domain.activateUser(action)),
      tap((response) => {
        this.store.dispatch(CloseLoadingScreen());
        this.snackbar.openSnackbar({ message: "Η αλλαγή του κωδικού πρόσβασης, ολοκληρώθηκε με επιτυχία." });
        this.router.navigate([`/login`]);
        return AuthActions.EMPTY();
      }),
      map(() => AuthActions.EMPTY()),
      catchError((e) => {
        this.store.dispatch(CloseLoadingScreen());
        // this.snackbar.openSnackbar({
        //   message: `${e?.error?.messages || 'Προέκυψε σφάλμα.'}`,
        //   cssClass: ['snackbar-error'],
        // });
        return throwError(e);
      })
    )
  );

  get_token_with_company_id$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.get_token_with_company_id),
      tap(() => this.store.dispatch(SetLoadingScreen({}))),

      switchMap((action) => this.domain.loginWithCompanyId(action.companyId)),
      map((response) => {
        return AuthActions.after_login(response);
      }),
      catchError((error) => {
        this.store.dispatch(CloseLoadingScreen());
        // some times when switching API gives 401 so user should logout in that case
        this.store.dispatch(AuthActions.logout());
        return throwError(error);
      })
    )
  );

  refresh$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refresh),
      switchMap((action) => this.domain.refreshToken()),
      map((response) => {
        return AuthActions.after_login(response);
      }),
      catchError((error) => {
        this.store.dispatch(AuthActions.logoutSuccess());   // If refresh fails --> already logged off, no need for API call only store cleanup
        return throwError(error);
      })
    )
  );


  download_manual_zip$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.download_manual_zip),
      tap(() => this.store.dispatch(SetLoadingScreen({}))),
      switchMap(() => this.domain.downloadManualZip()),
      tap((_) =>
        (this.snackbar.openSnackbar({
          message: 'Η λήψη του Manual Zip έγινε με επιτυχία.',
        }), this.store.dispatch(CloseLoadingScreen()))
      ),
      catchError((error) => {
        this.store.dispatch(CloseLoadingScreen());
        return throwError(error);
      })
    )
  );
}
