import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { HasAdminUser } from '@erbfactors/util-forms';

export class UserGroup {
  CugId: number;
  Guid: string;
  Title: string;
  User: string[];
}

export interface User {
  CuId: number;
  Guid: string;
  CuFullName: string;
  CuFatherName: string;
  CuEmail: string;
  IsAdmin: number;
  MobilePhone: string;
  UserRole?: number;
}
export const UserControls: (data?: User) => Record<keyof User, FormControl> = (
  data
) => ({
  CuId: new FormControl(data?.CuId),
  Guid: new FormControl(data?.Guid),
  CuFatherName: new FormControl(data?.CuFatherName),
  CuFullName: new FormControl(data?.CuFullName),
  CuEmail: new FormControl(data?.CuEmail),
  IsAdmin: new FormControl(data?.IsAdmin === 1),
  MobilePhone: new FormControl(data?.MobilePhone),
  UserRole: new FormControl(data?.IsAdmin)
});
export const UserGroupControls: (
  data?: UserGroup
) => Record<keyof UserGroup, FormControl> = (data) => ({
  CugId: new FormControl(data?.CugId),
  Title: new FormControl(data?.Title),
  Guid: new FormControl(data?.Guid),
  User: new FormControl(data?.User),
});
export class UserGroupsFormArray extends FormArray {
  controls: AbstractControl[];
  constructor(
    _controls: AbstractControl[] = [],
    Validators: ValidatorFn[] = []
  ) {
    super(_controls, Validators);
  }
  clear() {
    while (this.controls.length !== 0) {
      this.removeAt(0);
    }
  }
  patch(userGroups: UserGroup[]) {
    userGroups.forEach((group) => {

      const newUserGroup = new FormGroup({ ...UserGroupControls(group) });
      if (this.disabled) {
        newUserGroup.disable();
      }
      this.push(newUserGroup);
    });
  }
}
export class UsersFormArray extends FormArray {
  controls: AbstractControl[];
  constructor(
    _controls: AbstractControl[] = [],
    Validators: ValidatorFn[] = []
  ) {
    super(_controls, [HasAdminUser('IsAdmin'), ...Validators]);
  }
  getTransformedValue() {
    const value: User[] = this.getRawValue();
    return value.map((user) => ({ ...user, IsAdmin: user.IsAdmin ? 1 : 0 }));
  }
  clear() {
    while (this.controls.length !== 0) {
      this.removeAt(0);
    }
  }
  patch(users: User[]) {
    users.forEach((user) => {
      const newUser = new FormGroup({ ...UserControls(user) });
      if (this.disabled) {
        newUser.disable();
      }
      this.push(newUser);
    });
  }
}

