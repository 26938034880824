import { ValidatorFn, AbstractControl } from '@angular/forms';
export function ForeignVATValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    return vatValidation(control.value);
  };
}

export function vatValidation(val){
    var possibleSequences = sequentialDigits(10000);


    if (!val) return null;
    var repeatedDigitRegex = /([0-9])\1{4}/;
    const result = repeatedDigitRegex.test(val) || isContainSequence(possibleSequences, val) ;
    return result ? { vat: true }: null;



    function sequentialDigits(low, high?){
        const queue = [1, 2, 3, 4, 5, 6, 7, 8, 9];
        const ret = [];
        for (let idx = 0; idx < queue.length; ++idx) {
          const cur = queue[idx];
          const last = cur % 10;
          if (last === 9) continue;
          const next = cur * 10 + last + 1;
          if (next > high) continue;
          next >= low && ret.push(next);
          queue.push(next);
        }
        return ret;
      };

      function isContainSequence(sequences, val){
        let flag = false;
        sequences.forEach((seq)=>{
            if(val.indexOf(`${seq}`)!== -1){
                flag = true;
            }
        })
        return flag;
    }
}







