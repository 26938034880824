import { Role } from '../model';

export interface AuthState {
  username: string;
  token: string;
  expirationDate: Date;
  userId: string;
  companyId: number;
  email: string;
  roles: Role[];
  claims: {
    is_buyer: boolean;
    is_supplier: boolean;
    canViewReports: boolean;

  };
  available_companies: { name: string; id: number | string }[];
  mustResetPassword:boolean
}
export const AuthInitialState: AuthState = {
  username: null,
  token: null,
  expirationDate: null,
  companyId: null,
  email: null,
  userId: null,
  roles: [],
  claims: {
    is_buyer: null,
    is_supplier: null,
    canViewReports: null
  },
  available_companies: [],
  mustResetPassword: null
};
