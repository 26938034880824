import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FormField } from '@erbfactors/util-forms';

@Component({
  selector: 'erbfactors-checkbox-control',
  templateUrl: './checkbox-control.component.html',
  styleUrls: ['./checkbox-control.component.scss'],
})
export class CheckboxControlComponent implements OnInit {
  @Input() errors: { name: string; message: string }[] = [];
  @Input() showErrors = true;
  @Input() field: FormField;
  @Input() control: AbstractControl;
  @Input() form: FormGroup;
  styles: Partial<CSSStyleDeclaration>;
  ngOnInit() {
    this.styles = {
      display: 'flex',
      flexDirection: 'column',
      ...this.field.styles,
    };
  }
}
