import {
  animate,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const routeAnimations = trigger('routeAnimations', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({
          opacity: 0,
        }),
      ],
      { optional: true }
    ),
    group([
      query(':enter', [animate('1000ms ease-in-out', style({ opacity: 1 }))], {
        optional: true,
      }),
    ]),
  ]),
]);
