import { FormArray, ValidatorFn } from '@angular/forms';
export function HasAdminUser(controlName: string): ValidatorFn {
  return (formArray: FormArray): { [key: string]: boolean } | null => {
    const error = { hasAdminUser: true };
    let bool = false;
    formArray.controls.forEach((form) => {
      if (form.get(controlName)?.value || form.get('UserRole')?.value === 1 ) {
        bool = true;
      }
    });
    if (bool) return null;
    return error;
  };
}
