import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SnackBarService } from '@erbfactors/shared-ui';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { is_factor, is_buyer, is_supplier } from './../store/auth.selectors';

@Injectable()
export class IsBuyerAndSupplierGuard implements CanActivate {
 buyer: boolean;
 supplier: boolean;
  
    constructor(
    private store: Store,
    private router: Router,
    private snackbar: SnackBarService
  ) {}
  canActivate() {
    this.store.select(is_buyer).subscribe(
        isBuyer => {
            this.buyer = isBuyer;
            this.store.select(is_supplier).subscribe(
                isSupplier => {
                    if(this.buyer == true && isSupplier == true)
                       return true;

                }
            )
        }       
    );
    return false;
  }
}