import { ATTACHMENTS_FORM_NAME } from '../company-form.variable';
import { CompanyForm } from './../company-form.model';
import { FormMetata } from './../store/company-shared.state';
export function EnableForm(
  forms: CompanyForm,
  metadataMap: Map<string, FormMetata>
): void {
  for (const [formName, metadata] of metadataMap) {
    if (formName !== ATTACHMENTS_FORM_NAME) {
      const selectedForm = forms.get(formName);
      if (metadata.editable) {
        selectedForm.enable();
      } else {
        if (metadata.fields?.length) {
          for (const field of metadata.fields) {
            const formField = selectedForm.get(field.name);
            if (formField && field.editable) {
              formField.enable();
            }
          }
        }
      }
    }
  }
  console.log(forms);
}
