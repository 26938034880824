import { LayoutState } from './layout.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LAYOYT_STORE_KEY } from '../share-store.variable';
export const selectLayoutState = createFeatureSelector<LayoutState>(
  LAYOYT_STORE_KEY
);
export const selectPageTitle = createSelector(
  selectLayoutState,
  (state) => state.pageTitle
);
export const selectLoadingBar = createSelector(
  selectLayoutState,
  (state) => state.loadingBar
);
export const selectLoadingScreen = createSelector(
  selectLayoutState,
  (state) => state.loadingScreen
);
export const selectLoadingMessage = createSelector(
  selectLayoutState,
  (state) => state.loadingScreenMessage
);
