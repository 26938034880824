import { FormStatusEnum, LegalFormOption } from '../model';
export interface MetadataFormField {
  name: string;
  editable: boolean;
}
export interface FormMetata {
  editable: boolean;
  fields: MetadataFormField[];
}
export interface CompanySharedState {
  legalForm: LegalFormOption;
  legalForms: LegalFormOption[];
  guid: string;
  vrn: string;
  buyerName: string; // TARAS
  status: FormStatusEnum;
  statusDescription: string;
  formMetadata: Map<string, FormMetata>;
}
export const CompanySharedInitialState: CompanySharedState = {
  legalForm: null,
  legalForms: [],
  guid: null,
  vrn: null,
  buyerName: null,
  //buyerName: "TARAS",
  status: null,
  statusDescription: null,
  formMetadata: new Map(),
};
