import { createAction, props } from '@ngrx/store';
import { UpdateField } from '../model';
import { CommentsPositionType } from './forms.state';

export const add_update_field = createAction(
  'ADD_UPDATE_FIELD',
  props<{ field: UpdateField }>()
);
export const remove_update_field = createAction(
  'REMOVE_UPDATE_FIELD',
  props<{ guid: string }>()
);
export const clear_update_fields = createAction('CLEAR_UPDATE_FIELDS');
export const set_toggle_for_modify = createAction(
  'SET_TOGGLE_FOR_MODIFY',
  props<{ toogle: boolean }>()
);
export const reset_forms_store = createAction('RESET_FORMS_STORE');
export const set_approve_update_fields = createAction(
  'SET_APPROVE_UPDATE_FIELDS',
  props<{ fields: UpdateField[] }>()
);
export const set_comments = createAction(
  'SET_COMMENTS',
  props<{ comments: string }>()
);
export const reset_commnents = createAction('RESET_COMMENTS');
export const set_comments_position = createAction(
  'SET_POSITION',
  props<{ position: CommentsPositionType }>()
);
