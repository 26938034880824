import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  DuplicateShareholderVrnValidator,
  FormField,
  GreekVrnValidator,
  PercentageValidator,
  PercentSumValidator,
  PercentValidation,
  RequiredValidation,
  VrnValidation
} from '@erbfactors/util-forms';
import { of } from 'rxjs';
export interface Shareholder {
  CsFullname: string;
  CsVrn: string;
  CsIdentityNo: string;
  CsPercentage: number;
  CsId: number;
  IsLegalEntity: 1 | 0;
  IsForeignVatNumber: any;
}

export interface ShareholderMeta {
  CsFullname: string;
  CsVrn: string;
  CsIdentityNo: string;
  CsPercentage: number;
  CsId: number;
  IsLegalEntity: boolean;
  IsForeignVatNumber:number;
}

export type ShareholderControlType =
  | 'CsFullname'
  | 'CsVrn'
  | 'CsIdentityNo'
  | 'CsPercentage'
  | 'IsForeignVatNumber'
  | 'CsId';
export const ShareholderFieldConfig: FormField[] = [
  {
    displayName: 'Μέτοχοι / Εταίροι',
    name: 'CsFullname',
    type: 'text',
    validators: [Validators.required],
    validations: [RequiredValidation()],
    description:
      'Καταχωρείστε το Ονοματεπώνυμο  για φυσικά πρόσωπα ή την επωνυμία της εταιρείας που διατηρεί μερίδιο στην επιχείρησή σας.',
  },
  {
    displayName: 'Εθνικότητα ΑΦΜ',
    name: 'IsForeignVatNumber',
    type: 'select',
    valueField: 'value',
    displayField: 'displayValue',

    options: of([
      {
        value: "0",
        displayValue: 'Ελληνικό',
      },
      {
        value: "1",
        displayValue: 'Αλλοδαπό',
      },
    ]),
    defaultValue:"0",
    validators: [Validators.required],
    validations: [RequiredValidation()],
  },
  {
    displayName: 'ΑΦΜ',
    name: 'CsVrn',
    type: 'text',
    validators: [Validators.required, GreekVrnValidator()],
    validations: [RequiredValidation(), VrnValidation()],
  },
  {
    displayName: 'Αρ. Δελτίου Ταυτότητας',
    name: 'CsIdentityNo',
    type: 'text',
    styles: {
      minWidth: '200px',
    },
    validators: [Validators.required],
    validations: [RequiredValidation()],
    description:
      'Συμπληρώνεται μόνο για τις περιπτώσεις που Μέτοχος είναι Φυσικό πρόσωπο. Διαφορετικά καταχωρείστε «-»',
  },
  {
    displayName: 'Νομική Μορφή',
    name: 'IsLegalEntity',
    type: 'select',
    valueField: 'value',
    displayField: 'displayValue',

    options: of([
      {
        value: 1,
        displayValue: 'Εταιρεία',
      },
      {
        value: 0,
        displayValue: 'Φυσικό Πρόσωπο',
      },
    ]),
    validators: [Validators.required],
    validations: [RequiredValidation()],
  },
  {
    displayName: 'Ποσοστό συμμετοχής (%)',
    name: 'CsPercentage',
    type: 'number',
    min: 0,
    max: 100,
    maxLength: 4,
    validators: [Validators.required, PercentageValidator()],
    validations: [RequiredValidation()],
  },
  {
    displayName: '',
    name: 'CsId',
    type: 'text',
    hidden: true,
    defaultValue: 0,
    validators: [],
  },
];

export const ShareholderControls: (
  data?: Shareholder
) => Record<ShareholderControlType, AbstractControl> = (
  data?: Shareholder
) => ({
  CsFullname: new FormControl(data?.CsFullname, Validators.required),
  IsForeignVatNumber:new FormControl(data?.IsForeignVatNumber, Validators.required),
  CsVrn: new FormControl(data?.CsVrn, Validators.required),
  CsIdentityNo: new FormControl(data?.CsIdentityNo, Validators.required),
  CsPercentage: new FormControl(data?.CsPercentage, Validators.required),
  CsId: new FormControl(data?.CsId),
  IsLegalEntity: new FormControl(data?.IsLegalEntity, Validators.required),
});
export class ShareholdersFormArray extends FormArray {
  constructor(controls: AbstractControl[] = []) {
    super(controls, [
      PercentSumValidator('CsPercentage'),
      DuplicateShareholderVrnValidator('CsVrn'),
    ]);
  }
  getTransformedValue() {
    const value: Shareholder[] = this.getRawValue();
    return value.map((shareholder) => ({
      ...shareholder,
      IsLegalEntity: shareholder.IsLegalEntity ? 1 : 0,
    }));
  }
  clear() {
    while (this.controls.length !== 0) {
      this.removeAt(0);
    }
  }
  patch(shareholders: Shareholder[]) {
    for (let i = 0; i < shareholders.length; i++) {
      const group = new FormGroup({ ...ShareholderControls(shareholders[i]) });
      if (this.disabled) {
        group.disable();
      }
      this.push(group);
    }
  }
}
