import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { CompanyDetails } from '../company-form.model';
import { CompanyLocationResponse } from '../model';
import { CompanyMetaData } from '../model/company-metadata.model';
import { LegalFormOption, LegalFormResponse } from '../model/legal-form.model';

@Injectable()
export class CompanySharedApiService {
  private url = 'odata/legalforms';
  private storageKey = 'legalForms';
  private readonly get_company_metadata = `api/CompanyStable/GetMetadataInfo`;
  private get_company_url = 'api/CompanyStable';
  private get_company__changes_url = 'api/CompanyStable/GetChanges';
  private legal_forms_url = `odata/legalforms`;
  private locations_url = `/odata/locations`;
  constructor(private http: HttpClient) {}

  fetch_legal_forms(): Observable<LegalFormOption[]> {
    const storageItemsJSON = localStorage.getItem(this.storageKey);
    const storageItems = JSON.parse(storageItemsJSON);
    if (storageItemsJSON && storageItems.length > 0) {
      return of(storageItems);
    }
    return this.http.get<LegalFormResponse>(this.url).pipe(
      map((res) => res.value),
      tap((items) => {
        localStorage.setItem(this.storageKey, JSON.stringify(items));
      })
    ) as Observable<LegalFormOption[]>;
  }

  // GET CompanyMetadataInfo
  get_company_metadata_by_id(id: number): Observable<CompanyMetaData>{
    return this.http.get(
      `${this.get_company_metadata}/${id}`
    )as Observable<CompanyMetaData>;
  }

  // GET COMPANY INFO
  get_company(companyId?): Observable<CompanyDetails> {
    companyId || companyId === 0 ? companyId : companyId = '';
    return this.http
      .get(`${this.get_company_url}/${companyId}`)
      .pipe(tap((res) => console.log(res))) as Observable<CompanyDetails>;
  }

    // GET COMPANY CHANGES
    get_company_changes(companyId?): Observable<CompanyDetails> {
      companyId || companyId === 0 ? companyId : companyId = '';
      return this.http
        .get(`${this.get_company__changes_url}/${companyId}`)
        .pipe(tap((res) => console.log(res))) as Observable<CompanyDetails>;
    }


  async get_legal_forms(): Promise<LegalFormResponse>{
    return this.http.get<LegalFormResponse>(`${this.legal_forms_url}`).toPromise();
  }
  async get_location() : Promise<CompanyLocationResponse>{
    return this.http.get<CompanyLocationResponse>(`${this.locations_url}`).toPromise();
  }

  approve_reject(_approve: boolean, _companyId: string): Observable<any>{
    return this.http.post(
      `api/CompanyStable/approveRequest`,
      {
        IsApproved: _approve,
        companyId: _companyId
      }
    ) as Observable<any>;
  }
}
