export const DEFAULT_LOADING_SCREEN_MESSAGE = 'Παρακαλώ περιμένετε';
export interface LayoutState {
  menuOpened: boolean;
  loadingBar: boolean;
  loadingScreen: boolean;
  loadingScreenMessage: string;
  pageTitle: string;
}
export const initialLayoutState: LayoutState = {
  menuOpened: true,
  loadingBar: false,
  loadingScreen: false,
  loadingScreenMessage: DEFAULT_LOADING_SCREEN_MESSAGE,
  pageTitle: '',
};
