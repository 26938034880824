import { MatDatepickerModule } from '@angular/material/datepicker';
import { StoreModule } from '@ngrx/store';
import { FormlyModule } from '@ngx-formly/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { SharedUiModule } from '@erbfactors/shared-ui';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { CheckboxControlComponent } from './components/controls/checkbox-control/checkbox-control.component';
import { FormlyNgInputComponent } from './components/controls/formly-ng-input/formly-ng-input.component';
import { FormlyNgSelectComponent } from './components/controls/formly-ng-select/formly-ng-select.component';
import { NumberControlComponent } from './components/controls/number-control/number-control.component';
import { SelectControlComponent } from './components/controls/select-control/select-control.component';
import { TextControlComponent } from './components/controls/text-control/text-control.component';
import { FormGridComponent } from './components/form-grid/form-grid.component';
import { forms_reducer } from './store/forms.reducer';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormlyNgDatepickerComponent } from './components/controls/formly-ng-datepicker/formly-ng-datepicker.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FormlyNgInputAutocompleteComponent } from './components/controls/formly-ng-input-autocomplete/formly-ng-input-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormlyNgSelectAutocompleteComponent } from './components/controls/formly-ng-select-autocomplete/formly-ng-select-autocomplete.component';
import { DxPopoverModule, DxSelectBoxModule } from 'devextreme-angular';
import { PasswordControlComponent } from './components/controls/password-control/password-control.component';

@NgModule({
  declarations: [
    FormGridComponent,
    TextControlComponent,
    NumberControlComponent,
    CheckboxControlComponent,
    SelectControlComponent,
    FormlyNgInputComponent,
    FormlyNgSelectComponent,
    PasswordControlComponent,
    FormlyNgDatepickerComponent,
    FormlyNgInputAutocompleteComponent,
    FormlyNgSelectAutocompleteComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedUiModule,
    MatInputModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatTooltipModule,
    DxDataGridModule,
    FormlyModule,
    DxPopoverModule,
    StoreModule.forFeature('forms', forms_reducer),
    MatAutocompleteModule,
    DxSelectBoxModule,
  ],
  exports: [FormGridComponent, PasswordControlComponent],
})
export class UtilFormsModule {}
