import { environment } from '@erbfactors/environment';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { MsgResponse, LoginResponse } from './api.model';
import { Observable, of, throwError } from 'rxjs';
import { LoginDto, ResetPassDto } from '../dto';
import { SELECTED_COMPANY_LOCALSTORAGE_KEY } from '@erbfactors/feature-company/shared';
import { ChangePasswordDto } from '../dto/change-password.dto';
import { UserActivateDTO } from '../dto/user-activate.dto';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@erbfactors/shared-ui';
import { USER_STORAGE_KEY, logout } from '@erbfactors/util-auth';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as saveAs from 'file-saver';

@Injectable()
export class LoginApiService {
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private router: Router,
    private store: Store
  ) {}

  public login({ Username, Password }: LoginDto): Observable<LoginResponse> {
    const selected_company_json = localStorage.getItem(
      SELECTED_COMPANY_LOCALSTORAGE_KEY
    );
    let CompanyId;
    if (selected_company_json) {
      const selected_company = JSON.parse(selected_company_json) as {
        id: number;
        name: String;
      };
      CompanyId = selected_company.id;
    }
    return this.http
      .post(`${environment.apiUrl}/api/login/login`, {
        Username,
        Password,
        CompanyId,
      })
      .pipe(
        tap((res) => {
          console.log(res);
        }),
        switchMap((response: any) => {
          if(response.mustResetPassword){
            const mustResetPassword = this.dialog.open(
              ConfirmDialogComponent,
              {
                data: {
                  title: 'Ο κωδικός Πρόσβασης έχει λήξει',
                  description: 'Έχουν παρέλθει 6 μήνες από την τελευταία αλλαγή κωδικού. Πρέπει να ορίσετε νέο κωδικό πρόσβασης. Θα μεταφερθείτε στην σελίδα αλλαγής κωδικού πρόσβασης.',
                  cancel_action_name: 'Άκυρο',
                  confirm_action_name: 'Ναι'
                },
              }
            );
            return mustResetPassword.afterClosed().pipe(
              switchMap(result => {
                if (result) {
            this.router.navigate(['/user-activation']);
                }
            return of(response);
              })
            );
          }
          if (response.alreadyLoggedIn) { // current user is already logged in on another machine --> force login dialog
            const alreadyLoggedDialog = this.dialog.open(
              ConfirmDialogComponent,
              {
                data: {
                  title: 'Αυτός ο λογαριασμός είναι ήδη συνδεδεμένος.',
                  description: 'Προσπαθείτε να συνδεθείτε σε έναν λογαριασμό που χρησιμοποιείται ήδη ή έχετε συνδεθεί από άλλη θέση εργασίας ή άλλο browser. \n \nΜε την επιλογή Σύνδεση, θα διακοπούν οι λοιπές συνδέσεις σας και θα πραγματοποιηθεί η είσοδός στην εφαρμογή.',
                  cancel_action_name: 'Άκυρο',
                  confirm_action_name: 'Σύνδεση'
                },
              }
            );
            return alreadyLoggedDialog.afterClosed().pipe(
              switchMap(result => {
                if (result) {   // click force login on dialog
                  const headers = new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${response.auth_token}`
                  );
                  return this.http.post<any>('api/Login/ForceLogin', null, { headers })
                    .pipe(
                      switchMap(forceRes => {
                        if (forceRes === null) {    // response: 200 OK
                          response.alreadyLoggedIn = false;

                          return of(response);
                        }
                      }),
                      catchError((err) => {   // response: Error --> catch & rethrow
                        this.store.dispatch(logout());

                        return throwError(err);
                      })
                    );
                } else {    // click cancel force login on dialog
                  localStorage.removeItem(USER_STORAGE_KEY);
                  localStorage.removeItem(SELECTED_COMPANY_LOCALSTORAGE_KEY);
                  // this.store.dispatch(logout());
                  setTimeout(() => {
                    this.router.navigate(['/login']);
                    // this.store.dispatch(CloseLoadingScreen());
                    return of(response);
                  }, 2000);
                }
              })
            );


          } else {    // current user can proceed with login
            return of(response);
          }
        })
      ) as Observable<LoginResponse>;
  }

  public logout(): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Login/LogOff`, {});
  }
 
  public downloadManualZip():Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Manual/Download`,{ responseType: 'blob' }).pipe(
      map((res)=>{
        saveAs(res, 'usage_manual.zip');
        return res;
      })
    )
  }
   

  public forgot({ Email }: { Email: string }): Observable<MsgResponse> {
    return this.http.post(`${environment.apiUrl}/api/login/forgotpassword`, {
      Email,
    }) as Observable<MsgResponse>;
  }

  public getOTP({ Email }: { Email: string }): Observable<MsgResponse> {
    return this.http.post(
      `${environment.apiUrl}/api/login/GetRegistrationToken`,
      {
        UserEmail: Email,
      }
      //`${environment.apiUrl}/api/login/GetRegistrationToken?Email=${Email}`  if email is required to send as queryparams
    ) as Observable<MsgResponse>;
  }

  public changePassword({
    Password,
    NewPassword,
    ConfirmNewPassword,
  }: ChangePasswordDto): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/login/change`, {
      Password,
      NewPassword,
      ConfirmNewPassword,
    }) as Observable<MsgResponse>;
  }

  public reset({
    Email,
    Password,
    ConfirmPassword,
    Token,
  }: ResetPassDto): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/login/resetpassword`, {
      Email,
      Password,
      ConfirmPassword,
      Token,
    }) as Observable<MsgResponse>;
  }

  public activateUser({
    UserEmail,
    NewPassword,
    ConfirmNewPassword,
    Token,
  }: UserActivateDTO): Observable<MsgResponse> {
    return this.http.post(`${environment.apiUrl}/api/login/ActivateUser`, {
      UserEmail,
      NewPassword,
      ConfirmNewPassword,
      Token,
    }) as Observable<MsgResponse>;
  }

  public loginWithCompanyId(CompanyId: number) {
    return this.http
      .post(`${environment.apiUrl}/api/login/GetTokenWithCompanyId`, {
        CompanyId,
      })
      .pipe(
        tap((res) => {
          console.log(res);
        })
      ) as Observable<LoginResponse>;
  }

  public loginWithCompanyIdAsync(CompanyId: number) : Promise<LoginResponse> {
    return this.http
      .post<LoginResponse>(`${environment.apiUrl}/api/login/GetTokenWithCompanyId`, {
        CompanyId,
      }).toPromise();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public resetPassword(...args) {
    throw new Error('Not implemented Exception');
  }

  public getUserActivationEmail(userId : string): Observable<string>{
    return this.http.get(`${environment.apiUrl}/api/login/GetUserEmail/${userId}`)as Observable<string>;
  }

  public refreshToken() {
    return this.http
      .post(`${environment.apiUrl}/api/Login/RefreshToken`, {}, { withCredentials: true })
      .pipe(
        tap((res) => {
          console.log(res);
        })
      ) as Observable<LoginResponse>;
  }
}

