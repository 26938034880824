import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
  dataCyExtension,
  FormlyNgInputComponent,
  FormlyNgDatepickerComponent,
  FormlyNgSelectComponent,
  RequiredValidationMessage,
} from '@erbfactors/util-forms';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedUiModule } from '@erbfactors/shared-ui';
import { UtilAuthModule } from '@erbfactors/util-auth';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorInterceptor } from './interceptors';
import { TokenInterceptor } from './interceptors/token.interceptor';
import * as devextremeAjax from 'devextreme/core/utils/ajax.js';
import { sendRequestFactory } from './devexpress-http-client-helper';
import { FormlyModule } from '@ngx-formly/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FormlyNgInputAutocompleteComponent } from '../../../../libs/util-forms/src/lib/components/controls/formly-ng-input-autocomplete/formly-ng-input-autocomplete.component';
import { FormlyNgSelectAutocompleteComponent } from '../../../../libs/util-forms/src/lib/components/controls/formly-ng-select-autocomplete/formly-ng-select-autocomplete.component';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    MatSnackBarModule,
    SharedUiModule,
    UtilAuthModule,
    HttpClientModule,
    FormlyModule.forRoot({
      extensions: [{ name: 'datacy', extension: dataCyExtension }],
      types: [
        {
          name: 'erbf-text',
          component: FormlyNgInputComponent,
        },
        {
          name: 'erbf-number',
          extends: 'erbf-text',
          defaultOptions: {
            parsers: [Number],
            templateOptions: {
              type: 'number',
            },
          },
        },
        {
          name: 'erbf-select',
          component: FormlyNgSelectComponent,
        },
        {
          name: 'erbf-datepicker',
          component: FormlyNgDatepickerComponent,
        },
        {
          name: 'erbf-text-autocomplete',
          component: FormlyNgInputAutocompleteComponent,
        },
        {
          name: 'erbf-select-autocomplete',
          component: FormlyNgSelectAutocompleteComponent,
        },
      ],
      validationMessages: [
        {
          name: 'required',
          message: RequiredValidationMessage,
        },
        {
          name: 'vrn',
          message: 'Παρακαλώ εισάγετε ένα έγκυρο Α.Φ.Μ.',
        },
      ],
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalErrorInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'el-GR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: LOCALE_ID, useValue: 'el' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(httpClient: HttpClient) {
    devextremeAjax.inject({ sendRequest: sendRequestFactory(httpClient) });
  }
}
