<section
  class="color-blue control-container"
  [ngStyle]="styles"
  [formGroup]="form"
>
  <label [for]="field.name">
    {{ field.displayName }}
  </label>
  <mat-checkbox
    color="accent"
    fxFlex="grow"
    [name]="field.name"
    [attr.data-cy]="field.name"
    [formControlName]="field.name"
  ></mat-checkbox>
  <section *ngFor="let error of errors">
    <mat-error
      [@fadeInOut]
      *ngIf="showErrors && control.hasError(error.name) && control.touched"
      >{{ error.message }}</mat-error
    >
  </section>
</section>
